import wtv from "../images/wtv.png";
import games from "../images/gamess.png";
import newGame from "../images/modes.png";
import profile from "../images/profile.png";
import history from "../images/games.png";
import viewSelections from "../images/view-selections-tut.png";

import { useMediaQuery } from "react-responsive";

function TutExplore({ image, text }) {
  return (
    <div className="tour-text">
      {image && <img src={image} alt="" />}
      {text}
    </div>
  );
}

const CustomTooltip = ({ title, content }) => (
  <div className="custom-tooltip">
    {title && <h3>{title}</h3>}
    <p>{content}</p>
  </div>
);

let exploreText = (
  <p>
    Your explore page shows all games available for you to join.
    {/* {" "}<b>Click on join</b> to participate in any game of your choice */}
  </p>
);

let gamesText = (
  <p>
    Your games page shows live results of all your ongoing games,
    {/* {" "}<b>Click on any game tile</b> to view stats{" "} */}
  </p>
);

let newGameText = <p>The new game button allows you to create new games</p>;

let hisotyText = (
  <p>
    Your history page shows you results of all your concluded games.
    {/* {" "}<b>Click on any tile</b> to view stats{" "} */}
  </p>
);

let profileText = (
  <p>
    Your profile page shows all your account activity, gaming statistics and
    cash won
  </p>
);

let getStarted = (
  <p>
    Let's get you started with your first 1v1 game <br />{" "}
    <b>Click on the new game button </b> to create a new game
  </p>
);

// new game
let newGame1v1 = (
  <p>
    Play against one opponent. Get the highest score to win. <br />
    {/* <b>Versuz Streaks:</b> Win up to N5,000 by beating three players in a row. */}
  </p>
);

let newGame1v12 = (
  <p>
    To create a <b>1v1</b> game
    <br />
    <b>Click</b> on the 1v1 tile then
    <br /> <b>Choose</b> the version to play
  </p>
);

let newGameLeague = (
  <p>A multiplayer game where the player with the highest score wins</p>
);

let newGamePool = (
  <p>
    A multiplayer game where each player can cashout based on their accumulated
    odds
  </p>
);

let create1v1 = (
  <p>
    Let's create your first 1v1 Match. <br /> <b>Click</b> the 1v1 tile to
    create a new 1v1 Game{" "}
  </p>
);

// new 1v1
let pair1v1 = (
  <p>
    <b>Click</b> on a variant tile to create a 1v1 game
  </p>
);

let explore1v1 = (
  <p>
    Create a 1v1 game and allow any random opponent to join, your games will be
    displayed on the explore page
  </p>
);

let streak1v1 = (
  <p>
    Play 1v1 against random opponents and <b>win up to ₦5,000 </b> everytime you
    beat 3 opponents in a row
  </p>
);

let playstreak1v1 = (
  <p>
    <b>Click</b> on the streak tile to create your first streak game
  </p>
);

export default [
  {
    target: '[data-tut="nav-step-1"]',
    content: <TutExplore image={null} text={exploreText} />,
    disableBeacon: true,
    buttons: {
      next: {
        'data-button-type': 'primary'
      },
    },
  },
  {
    target: '[data-tut="nav-step-2"]',
    content: <TutExplore image={null} text={gamesText} />,
  },
  {
    target: '[data-tut="nav-step-3"]',
    content: <TutExplore image={null} text={newGameText} />,
  },
  
  {
    target: '[data-tut="nav-step-4"]',
    content: <TutExplore image={null} text={hisotyText} />,
  },
  {
    target: '[data-tut="nav-step-5"]',
    content: <TutExplore image={null} text={profileText} />,
  },
  {
    target: '[data-tut="nav-step-3"]',
    content: <TutExplore image={null} text={getStarted} />,
    hideFooter: true,
  },
  
];

export const desktopSteps =  [
  {
    target: '[data-tut="nav-step-1"]',
    content: <TutExplore image={null} text={exploreText} />,
    disableBeacon: true,
    buttons: {
      next: {
        'data-button-type': 'primary'
      },
    },
  },
  {
    target: '[data-tut="nav-step-2"]',
    content: <TutExplore image={null} text={gamesText} />,
  },
  // {
  //   target: '[data-tut="nav-step-3.1"]',
  //   content: <TutExplore image={null} text={newGameText} />,
  // },
  {
    target: '[data-tut="nav-step-4"]',
    content: <TutExplore image={null} text={hisotyText} />,
  },
  {
    target: '[data-tut="nav-step-5"]',
    content: <TutExplore image={null} text={profileText} />,
  },
  {
    target: '[data-tut="nav-step-3.1"]',
    content: <TutExplore image={null} text={getStarted} />,
    hideFooter: true,
  },
];

export const newGameSteps = [
  {
    target: '[data-tut="new-game-step-1"]',
    content: <TutExplore image={null} text={newGame1v1} />,
    disableBeacon: true,
    // hideFooter: true,
    // offset:0,
  },
  {
    target: '[data-tut="new-game-step-2"]',
    content: <TutExplore image={null} text={newGameLeague} />,
    // disableBeacon: true,
    // hideFooter: true,
    // offset:0,
  },
  {
    target: '[data-tut="new-game-step-3"]',
    content: <TutExplore image={null} text={newGamePool} />,
    // hideFooter: true,
    // disableBeacon: true,
    // offset:0,
  },
  {
    target: '[data-tut="new-game-step-1"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            {" "}
            Let's create your first 1v1 <br />
            <b>Click</b> on the 1v1 tile to create a game
          </p>
        }
      />
    ),
    disableBeacon: true,
    hideFooter: true,
    // offset:0,
  },
];

export const new1v1Steps = [
  {
    target: '[data-tut="new-1v1-step-1"]',
    content: <TutExplore image={null} text={pair1v1} />,
    disableBeacon: true,
    
    offset: 0,
    hideFooter: true,
  },
  // {
  //   target: '[data-tut="new-1v1-step-2"]',
  //   content: <TutExplore image={null} text={explore1v1} />,
  // },
  // {
  //   target: '[data-tut="new-1v1-step-3"]',
  //   content: <TutExplore image={null} text={streak1v1} />,
  // },
  // {
  //   target: '[data-tut="new-1v1-step-3"]',
  //   content: <TutExplore image={null} text={playstreak1v1} />,
  // },
];

export const regionsSteps = [
  {
    target: '[data-tut="view-selections"]',
    content: (
      <TutExplore
        image={viewSelections}
        text={
          <p>
            The selection tracker turns green when you have the right amount of
            selections required to create or join a game{" "}
          </p>
        }
      />
    ),
    disableBeacon: true,
    
    offset: 0,
    // hideFooter: true,
  },
  {
    target: '[data-tut="clear-ticket"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            The trash icon can be used to clear all your selections
          </p>
        }
      />
    ),
    disableBeacon: true,
    
    offset: 0,
    // hideFooter: true,
  },
  {
    target: '[data-tut="back"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            The back button allows you to go back to the previous page
          </p>
        }
      />
    ),
    disableBeacon: true,
    
    offset: 0,
    // hideFooter: true,
  },
  {
    target: '[data-tut="select-region-step-0"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            <b>Click</b> on a region to see the available competitions in that region
          </p>
        }
      />
    ),
    disableBeacon: true,
    placement: "top",
    // offset: 0,
    hideFooter: true,
  },
];

export const competitonsSteps = [
  {
    target: '[data-tut="select-comp-step-0"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            <b>Click</b> on a competiton to see the available matches{" "}
          </p>
        }
      />
    ),
    disableBeacon: true,
    // placement: "bottom",
    // offset: 0,
    hideFooter: true,
  },
];

export const fixturesSteps = [
  {
    target: '[data-tut="select-fixture-step-0"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            <b>Click</b> on a match to see the available options{" "}
          </p>
        }
      />
    ),
    disableBeacon: true,
    placement: " bottom",
    offset: 0,
    hideFooter: true,
  },
];

export const matchSteps = [
  
  // {
  //   target: '[data-tut="select-match-step-0"]',
  //   content: (
  //     <TutExplore
  //       image={null}
  //       text={
  //         <p>
  //           You can choose <b>multiple selections</b> from the same match{" "}
  //         </p>
  //       }
  //     />
  //   ),
  //   disableBeacon: true,
  //   
  //   offset: 0,
  //   // hideFooter: true,
  // },
  {
    target: '[data-tut="select-match-step-0"]',
    content: (
      <TutExplore
        image={viewSelections}
        text={
          <p>
            To create your first game <b>choose 10 selections</b> from any competiton of your choice then  <b>Click</b> on the selection tracker when it turns green   
          </p>
        }
      />
    ),
    disableBeacon: true,
    // 
    offset: 0,
    hideFooter: true,
  },
  
  // {
  //   target: '[data-tut="view-selections"]',
  //   content: (
  //     <TutExplore
  //       image={null}
  //       text={
  //         <p>
  //           <b>Click</b> on the selection tracket to view all your selections{" "}
  //         </p>
  //       }
  //     />
  //   ),
  //   disableBeacon: true,
  //   
  //   offset: 0,
  //   hideFooter: true,
  // },
];

export const mainSteps = [
  {
    target: "body",
    content: (
      <TutExplore
        image={wtv}
        text={
          <>
            <p style={{ marginBottom: "0px" }}>
              Here, it's all about beating opponents, rising up the ranks and
              cashing out!!! Let's show you around the platform
            </p>
            {/* <button style={{width:'100%'}}>
              SKIP Tour
            </button> */}
          </>
        }
      />
    ),
    disableBeacon: true,
    // hideFooter: true,
    offset: 0,
    placement: "center",
    title: <b>Welcome to Versuz</b>,
    buttons: {
      next: {
        className: "joyride-modal__button--next", // Apply custom class
      },
    },
  },
];

export const selectionSteps = [
  {
    target: '[data-tut="selections-step-0"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            {" "}
            You get to see the total amount of odds your picks can accumulate{" "}
          </p>
        }
      />
    ),
    disableBeacon: true,
  },
  {
    target: '[data-tut="selections-step-1"]',
    content: (
      <TutExplore
        image={null}
        text={
          <p>
            {" "}
            Enter a stake amount and click on continue or login to create your game
          </p>
        }
      />
    ),
  },
  // {
  //   target: '[data-tut="selections-step-1"]',
  //   content: (
  //     <TutExplore
  //       image={null}
  //       text={
  //         <p>
  //           {" "}
  //           <b>Click</b> continue to create your game{" "}
  //         </p>
  //       }
  //     />
  //   ),
  // },
];

// onClick={() => closeTut("main")}
// onClick={() => closeTut("intro")}
// onClick={() => closeTut("gameTypeModal")}
// onClick={() => closeTut("new1v1")}
// onClick={() => closeTut("regions")}
// onClick={() => closeTut("competitons")}
// onClick={() => closeTut("fixtures")}
// onClick={() => closeTut("match")}
// onClick={() => closeTut("selections")}

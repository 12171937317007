import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useContext } from "react";
import { sortedArray, SortedArrayEndDate } from "../../App.js";

export const getAppData = createAsyncThunk(
  "getAppData",
  async (payload) => {
    const url = process.env.REACT_APP_BASE_URL;
    const authToken = localStorage.getItem("vsrz") ? `Token ${JSON.parse(localStorage.getItem("vsrz")).token}` : 'Token ';

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
        "Authorization": authToken
      },
      // Token ${JSON.parse(localStorage.getItem("vsrz")).token}
      // body: JSON.stringify(payload),
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => (err));

    return response;
  }
);

export const generalSlice = createSlice({
  // Authentication state
  name: "general",
  initialState: {
    countries: null,
    explore: null,
    leagues: null,
    games: null,
    history: null,
    profile: null,
    weeklyTournament: null,
    prevLocation: null,
    ticketData: null,
    loading: false,
    historyLoading: false,
    allGames: null,
    ranks: [],
    banks: [],
    tournamentLoading: false,
  },
  reducers: {
    setGeneralState: (state, action) => {
      state = JSON.parse(action.payload)
    },
    setprevLocation: (state, action) => {
      state.prevLocation = action.payload
    },
    setTicketData: (state, action) => {
      state.ticketData = action.payload
    },
    setHistory: (state, action) => {
      state.history = {
        // count: action.payload.count,
        // next: action.payload.next,
        // previous: action.payload.previous,
        // results: SortedArrayEndDate(action.payload.results),
        results: SortedArrayEndDate(action.payload.games),
      };
    },
    setBanks: (state, action) =>{
      state.banks = action.payload
    },
    setWeeklyTournament: (state, action) => {
      state.weeklyTournament = action.payload;
    },
    setHistoryLoading:(state, action) => {
      state.historyLoading = action.payload
    },
    setTournamentLoading:(state, action) =>{
      state.tournamentLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAppData.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(getAppData.rejected, (state, action) => {
      state.loading = false
    });
    builder.addCase(getAppData.fulfilled, (state, action) => {
      state.explore = sortedArray(action.payload.explore_page.unpaired_betcodes);
      state.leagues = sortedArray(action.payload.explore_page.leagues);
      state.countries = action.payload.countries_page.data;
      state.allGames = sortedArray([...action.payload.explore_page.unpaired_betcodes, ...action.payload.explore_page.leagues]);
      state.ranks = action.payload.ranks;

      if (!action.payload.logged_in) {
        localStorage.setItem("vsrz", "")
      } else {
        state.profile = action.payload.profile_page.data;
        localStorage.setItem("balance", action.payload.profile_page.data.money_balance);
        

        state.games = {
          paired: action.payload.games_page.paired_betcodes,
          unpaired: action.payload.games_page.unpaired_betcodes,
          leagues: action.payload.games_page.leagues,
          cuppools: action.payload.games_page.cuppools,
          all: sortedArray([...action.payload.games_page.paired_betcodes, ...action.payload.games_page.unpaired_betcodes, ...action.payload.games_page.leagues, ...action.payload.games_page.cuppools])
        }
        // state.weeklyTournament = action.payload.weekly_tournament;
      }
      state.loading = false
    });
  },
});


export const { setGeneralState, setprevLocation, setTicketData, setHistory, setWeeklyTournament, setHistoryLoading, setBanks, setTournamentLoading } = generalSlice.actions;
export default generalSlice.reducer;


import React, { useEffect, useState } from "react";

function CountdownTimer(props) {
  const { date } = props;
  const targetTime = new Date(date).getTime();
  const [timeRemaining, setTimeRemaining] = useState(null);

  function calculateTimeRemaining() {
    const currentTime = new Date().getTime();
    const timeRemaining = targetTime - currentTime;
  
    if (timeRemaining <= 0) {
      // Timer has expired
      return { days: "0", hours: "0", minutes: "0", seconds: "0" };
    }
  
    const totalSeconds = Math.floor(timeRemaining / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const totalHours = Math.floor(totalMinutes / 60);
    const hours = totalHours % 24;
    const days = Math.floor(totalHours / 24);
  
    return {
      days: days < 10 ? `${days}` : days.toString(),
      hours: hours < 10 ? `${hours}` : hours.toString(),
      minutes: minutes < 10 ? `${minutes}` : minutes.toString(),
      // seconds: seconds < 10 ? `${seconds}` : seconds.toString(),
    };
  }
  
  

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {timeRemaining === null ? (
        " "
      ) : (
          <span style={{ display: "flex", gap: "3px" }} className="justify-center">
            <span>{timeRemaining.days > 0 && `${timeRemaining.days}d:`}</span>
            <span>{timeRemaining.hours}h:</span>
            <span>{timeRemaining.minutes}m</span>
            {/* <span>{timeRemaining.seconds}s</span> */}
          </span>
      )}
    </>
  );
}

export default CountdownTimer;

import TutorialComponent from "./TutorialComponent";
import wtv from "../../images/wtv.png";

export default [
  {
    target: "body",
    content: (
      <TutorialComponent
        image={wtv}
        text={
          <>
            <p style={{ marginBottom: "0px" }}>
              Here, it's all about beating opponents, rising up the ranks and
              cashing out!!! Let's show you around the platform
            </p>
            {/* <button style={{width:'100%'}}>
                SKIP Tour
              </button> */}
          </>
        }
      />
    ),
    disableBeacon: true,
    // hideFooter: true,
    offset: 0,
    placement: "center",
    title: <b>Welcome to Versuz</b>,
    buttons: {
      next: {
        className: "joyride-modal__button--next", // Apply custom class
      },
    },
  },
];

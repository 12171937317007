import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import toast, { Toaster } from "react-hot-toast";

// console.log(process.env.REACT_APP_AUTH_DOMAIN)

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}
// console.log(config)

const app = initializeApp(config);
// Messaging service
export const messaging = getMessaging(app);

function requestPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        }
    })
}
export function Ask() {
    getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            const saveToken = async (token) => {
                const url = `${process.env.REACT_APP_BASE_URL}/save_token`;
                const payload = { "token": token };
                const config = {
                    method: "POST",
                    headers: {
                        Authorization: `Token ${JSON.parse(localStorage.getItem("vsrz")).token}`,
                        reactkey: process.env.REACT_APP_AUTH_KEY,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                };
                const response = await fetch(url, config)
                    .then((data) => data.json())
                if (response.success) {
                    // console.log('')
                }
            }
            saveToken(currentToken);
        } else {
            // console.log('No registration token available. Request permission to generate one.');
            requestPermission();
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
    })

}

onMessage(messaging, (payload) => {
    toast(payload.notification.body)
});


import "../styles/fixtures.css";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, convertTo12HourFormat } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFixture } from "../redux/slices/selectSlice";
import { setCurrentTour } from "../redux/slices/tourSlice";



export default function Fixture(props) {
  const { fixture, hover = true } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ticketSelections = useSelector((state) => state.ticket.selectedOptions);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  function handleClick(i) {
    dispatch(setCurrentFixture(fixture));
    navigate(`/fixture/${i}`);
    closeTut("match");
  }
  return (
    <div
      className={`p-3 between-row ${hover && "fixture"} cursor ${
        ticketSelections[fixture.id] && "selected"
      } sans`}
      onClick={hover ? () => handleClick(fixture.id) : null}
    >
      <div className="">
        <div className="align-row-center mb-1">
          <img src={fixture.home_team_crest} height="25px" width="25px" />
          <div className="ms-2 medium seven">{fixture.home_team}</div>
        </div>
        <div className="align-row-center mb-1">
          <img src={fixture.away_team_crest} height="25px" width="25px" />
          <div className="ms-2 medium seven">{fixture.away_team}</div>
        </div>
      </div>
      <div className="border-start small ps-3 text-center">
        <div className="">{fixture.date.split(" ")[0]}</div>
        <div>
          <b>{convertTo12HourFormat(fixture.date.split(" ")[1])}</b>
        </div>
      </div>
    </div>
  );
}
export function Terms(){
    return (
        <div style={{padding:'20px'}} className='sans'>
            <h2 style={{textAlign:'center'}}>Versuz Terms and Conditions</h2>
            <p>Welcome to Versuz, a peer-to-peer gaming platform designed to bring gamers together in friendly competition. By using Versuz, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before using the platform.</p>
            <ol>
                <li><strong>Acceptance of Terms:</strong> By accessing or using Versuz, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions, as well as any additional guidelines or rules posted on the platform.</li>
                <li><strong>User Eligibility:</strong> You must be at least 18 years old to use Versuz or have the consent of a legal guardian. By using the platform, you represent and warrant that you have the right, authority, and capacity to enter into these terms and to abide by all of the terms and conditions herein.</li>
                <li><strong>Account Registration:</strong> To access certain features of Versuz, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</li>
                <li><strong>User Conduct:</strong> You agree not to use Versuz for any unlawful purpose or in any way that violates these terms and conditions. You further agree not to:
                    <ul>
                        <li>Harass, intimidate, or threaten other users.</li>
                        <li>Post or transmit any content that is infringing, obscene, defamatory, or otherwise objectionable.</li>
                        <li>Attempt to gain unauthorized access to other users' accounts or information.</li>
                        <li>Engage in any activity that disrupts or interferes with the operation of Versuz.</li>
                    </ul>
                </li>
                <li><strong>Intellectual Property:</strong> All content on Versuz, including but not limited to text, graphics, logos, images, and software, is the property of Versuz or its licensors and is protected by copyright, trademark, and other intellectual property laws.</li>
                <li><strong>Disclaimer of Warranties:</strong> Versuz is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not guarantee that Versuz will always be available, uninterrupted, or error-free, and we disclaim any liability for any loss or damage arising from your use of the platform.</li>
                <li><strong>Limitation of Liability:</strong> To the fullest extent permitted by law, Versuz and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to damages for lost profits, loss of data, or loss of goodwill, arising out of or in connection with your use of Versuz.</li>
                <li><strong>Governing Law:</strong> These terms and conditions shall be governed by and construed in accordance with the laws of Nigeria. Any dispute arising out of or relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts located in Nigeria.</li>
                <li><strong>Changes to Terms:</strong> Versuz reserves the right to modify or revise these terms and conditions at any time, and your continued use of the platform following any such changes shall constitute your acceptance of such changes.</li>
                <li><strong>Fraudulent Activity:</strong> Versuz has a zero-tolerance policy for fraudulent activity on the platform. Any user found engaging in fraudulent acts, including but not limited to the following, will be subject to immediate account suspension or termination, and legal action may be pursued:
                    <ul>
                        <li>Manipulating game outcomes or scores through unauthorized means.</li>
                        <li>Impersonating another user or falsely representing oneself.</li>
                        <li>Using automated scripts, bots, or other software to gain unfair advantages.</li>
                        <li>Engaging in phishing or other scams to deceive users or obtain personal information.</li>
                        <li>Exploiting glitches, bugs, or vulnerabilities in the platform for personal gain.</li>
                        <li>Attempting to defraud other users through unauthorized transactions or trades.</li>
                    </ul>
                    Versuz reserves the right to investigate and take appropriate action against any suspected fraudulent activity, including but not limited to account suspension, termination, and reporting to law enforcement authorities. Users are encouraged to report any suspicious activity or suspected fraudulent behavior to Versuz immediately for investigation.</li>
                <li><strong>Contact Us:</strong> If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:support@versuz.co">support@versuz.co</a>.</li>
            </ol>
        </div>
    )
}

import TutorialComponent from "./TutorialComponent";
// competitonsSteps
export default [
    {
      target: '[data-tut="select-comp-step-0"]',
      content: (
        <TutorialComponent
          image={null}
          text={
            <p>
              <b>Click</b> on a competiton to see the available matches{" "}
            </p>
          }
        />
      ),
      disableBeacon: true,
      // placement: "bottom",
      // offset: 0,
      hideFooter: true,
    },
  ];
import React from "react";



export default function TutorialComponent({ image, text }) {
  return (
    <div className="tour-text">
      {image && <img src={image} alt="" />}
      {text}
    </div>
  );
}

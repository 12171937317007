import Layout from "./layout";
import def from "../images/default.png";
import { Picture } from "./cards";
import "../index.css";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../App";
import Fixture from "./fixture";
import { useDispatch } from "react-redux";
import { setPairDetails } from "../redux/slices/ticketSlice";
import toast from "react-hot-toast";

export default function Join() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { id } = useParams();

  const getGames = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/betcode/${id}`;

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, config).then((data) => data.json());

    if (response.success) {
      if (response.data.streak) {
        toast.error("You can not join this game");
        navigate("/");
        // window.history.back()
      } else {
        setData(response.data);
      }
    } else {
      navigate("/");
    }
    setLoading(false);
  };

  useEffect(() => {
    getGames();
  }, []);

  function proceed() {
    let stake = data.stake;
    dispatch(
      setPairDetails({
        id,
        stake,
        number_of_games: data.fixtures.length,
        pairType: "",
      })
    );
    navigate(`/select/regions`);
  }

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        data && (
          <div className="join-cont">
            <div className="text-center">
              <div className="between-col">
                <Picture image={data.user.profile_pic} size="100" />
                <span className="seven">{data.user.username}</span>
              </div>

              <div className="small between-row join-game-stats col-12 col-md-6 col-lg-4 mx-auto p-3 my-3">
                <div>
                  <div>Stake Amount</div>
                  <div className="mt-2 larger">
                    ₦<b>{data.stake}</b>
                  </div>
                </div>
                <div>
                  <div>Total Odds</div>
                  <div className="mt-2 larger">
                    <b>{data.odds}</b>
                  </div>
                </div>
                <div>
                  <div>Total Games</div>
                  <div className="mt-2 larger">
                    <b>{data.fixtures.length}</b>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mx-auto">
                <button className="btn orange w-100 button sans" onClick={proceed}>
                    Continue
                </button>

              </div>
            </div>
            <div className="mt-3  col-12 col-md-11 col-lg-9 m-auto sans join-fixtures-cont">
              {data.fixtures.map((f, i) => {
                return (
                  <div
                    key={i}
                    className="col-12 col-md-5 my-1 bg-light-subtle rounded"
                    
                  >
                    <Fixture fixture={f.fixture} hover={false} />
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}
    </Layout>
  );
}

import TutorialComponent from "./TutorialComponent";
import exploreJoinGames from "../../images/explore-join-game.webp";
import gameDetails from "../../images/game-details.webp";
import profile from "../../images/profile.webp";

export default [
  {
    target: '[data-tut="nav-step-1"]',
    content: (
      <TutorialComponent
        // image={exploreJoinGames}
        image={null}
        text={
          <p>
            Your explore page shows all available games. To join a game click
            join
          </p>
        }
      />
    ),
    disableBeacon: true,
    buttons: {
      next: {
        "data-button-type": "primary",
      },
    },
  },

  {
    target: '[data-tut="nav-step-2"]',
    content: (
      <TutorialComponent
        // image={gameDetails}
        image={null}
        text={
          <p>
            Your games page shows all your ongoing games. Click on a tile to
            view the live results
          </p>
        }
      />
    ),
  },

  {
    target: '[data-tut="nav-step-3"]',
    content: (
      <TutorialComponent
        image={null}
        text={<p>The new game button allows you to create new games</p>}
      />
    ),
  },

  {
    target: '[data-tut="nav-step-4"]',
    content: (
      <TutorialComponent
        // image={gameDetails}
        image={null}
        text={
          <p>
            Your history page shows all your concluded games. Click on a tile to
            view the results
          </p>
        }
      />
    ),
  },
  {
    target: '[data-tut="nav-step-5"]',
    content: (
      <TutorialComponent
        // image={profile}
        image={null}
        text={
          <p>
            Your profile page shows all your account activity, gaming statistics
            and cash won
          </p>
        }
      />
    ),
  },
  {
    target: '[data-tut="nav-step-3"]',
    content: (
      <TutorialComponent
        image={null}
        text={
          <p>
            Now that you know your way around, <br /> <b>Click </b> the new game
            button to create your first game
          </p>
        }
      />
    ),
    hideFooter: true,
  },
];

export const desktopSteps = [
  {
    target: '[data-tut="nav-step-1"]',
    content: (
      <TutorialComponent
        // image={exploreJoinGames}
        image={null}
        text={
          <p>
            Your explore page shows all available games. To join a game click
            join
          </p>
        }
      />
    ),
    disableBeacon: true,
    buttons: {
      next: {
        "data-button-type": "primary",
      },
    },
  },
  {
    target: '[data-tut="nav-step-2"]',
    content: (
      <TutorialComponent
        // image={gameDetails}
        image={null}
        text={
          <p>
            Your games page shows all your ongoing games. Click on a tile to
            view the live results
          </p>
        }
      />
    ),
  },
  // {
  //   target: '[data-tut="nav-step-3.1"]',
  //   content: <TutorialComponent image={null} text={newGameText} />,
  // },
  {
    target: '[data-tut="nav-step-4"]',
    content: (
      <TutorialComponent
        // image={gameDetails}
        image={null}
        text={
          <p>
            Your history page shows all your concluded games. Click on a tile to
            view the results
          </p>
        }
      />
    ),
  },
  {
    target: '[data-tut="nav-step-5"]',
    content: (
      <TutorialComponent
        // image={profile}
        image={null}
        text={
          <p>
            Your profile page shows all your account activity, gaming statistics
            and cash won
          </p>
        }
      />
    ),
  },
  {
    target: '[data-tut="nav-step-3.1"]',
    content: (
      <TutorialComponent
        image={null}
        text={
          <p>
            Now that you know your way around, <br /> <b>Click </b> the new game
            button to create your first game
          </p>
        }
      />
    ),
    hideFooter: true,
  },
];

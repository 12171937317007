import { useEffect, useState } from "react";
import Layout from "../components/layout";
import { GamesDiv } from "./explore";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../App";
import Login from "../components/login";
import { setHistory } from "../redux/slices/generalSlice";
import { useNavigate } from "react-router-dom";

export default function History() {
  const games = useSelector((state) => state.general.history?.results);
  const loading = useSelector((state) => state.general.historyLoading);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const [historyLoad, setHistoryLoad] = useState(false);

  return (
    <>
      {authenticated ? (
        <Layout>
          <div className="">
            <div className="page-header-text" style={{padding:'0px 1rem'}}>
              <h4>
                <b>History</b>
              </h4>
              <span></span>
            </div>
            <div className="history-body">
                {loading || historyLoad ? (
                <Loading />
                ) : (
                // <GamesDiv games={games ? games : []} showNav={true}/>
                <GamesDiv games={games ? games : []} history={true} />
                )}
            </div>

            </div>
        </Layout>
      ) : (
        <Login reload={true} />
      )}
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";



// const disableBody = (target) => disableBodyScroll(target);
// const enableBody = (target) => enableBodyScroll(target);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    {/* <TourProvider
      steps={steps}
      afterOpen={disableBody}
      beforeClose={enableBody}
    >
    </TourProvider> */}

      <Provider store={store}>
        <App />
      </Provider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

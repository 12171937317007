import copa from '../images/copa image.webp';
import euro from '../images/euro-image.webp';
import euro_org from '../images/euro-org.webp';
import copa_org from '../images/copa-org.webp';
import { useEffect, useState } from 'react';
import '../styles/cups.css'
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutHeader, Navbar } from '../components/layout';
import { ColumnHeader, Back } from './select';
import { useMediaQuery } from "react-responsive";
import { LoginButton } from '../components/login';
import { useSelector } from 'react-redux';
import { Error } from './profile';
import { Picture } from '../components/cards';
import { Forecast } from './game';
import toast from 'react-hot-toast';

export default function Cup(props) {
    const navigate = useNavigate();
    const { name, size = '100px', cuppage = false, potential_win, present, total, selectedTeam } = props;

    let pic;
    name === 'euro' ? (
        pic = <img src={euro_org} height={size} width={size} style={{ opacity: '1' }} className='rounded-circle' />
    ) : (
        pic = <img src={copa_org} height={size} width={size} style={{ opacity: '1' }} className='rounded-circle' />
    )

    let bg_color;
    if (name === 'euro') {
        bg_color = 'linear-gradient(105.24deg, #002C92 13.48%, #143CDB 91.75%)';
    } else if (name === 'copa') {
        bg_color = '#FD2A2A';
    }

    useEffect(() => {

    }, [name, bg_color])

    function joinCupPool() {
        // console.log(name)
        navigate(`/pool/${name}`)
    }

    return (
        // <img src={pic} className='col-12' height='150px'/>
        <div className='col-12 between-row text-white rounded-4 px-3 carousel-cup' style={{  background: bg_color, zIndex: '1' }}>
            <div className='between-col col-7 col-md-5 align-items-start gap-1'>
                <h6 className='card-header-text'>
                    {name === 'euro' ? 'EURO 2024' : 'COPA AMERICA'}
                </h6>
                <div className='smal mb-3 card-sub-text'>
                    {selectedTeam ? (
                        selectedTeam
                    ) : (
                        `Back a team in the ${name === 'euro' ? 'UEFA EURO CUP' : 'COPA AMERICA '}`
                    )}
                    {!cuppage && (
                        <div className='text-white bg-white p-1 col-6 col-md-10 join-btn text-center rounded mt-2 cursor' onClick={joinCupPool}>Join</div>
                    )}
                    {cuppage ? (
                        <div className='d-flex flex-column gap-3 mt-2'>
                            <span className='smaller'>*Potential Win</span>
                            <span>₦{present ? total : parseInt(potential_win)}</span>
                        </div>
                    ) : ''}
                </div>
            </div>
            <div className='col-4 text-end col-md-5'>
                {pic}
            </div>
        </div>
    )
}

export function CupCarousel() {
    return (
        <div id="cupCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item" data-bs-interval='3000'>
                    <Cup name='copa' />
                </div>
                <div className="carousel-item active" data-bs-interval='3000'>
                    <Cup name='euro' />
                </div>
            </div>
            <button className="carousel-control-prev h-25" style={{ top: '50px' }} type="button" data-bs-target="#cupCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next h-25" style={{ top: '50px' }} type="button" data-bs-target="#cupCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export function CupPage() {
    const { name } = useParams();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [countries, setCountries] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [potential_win, setPotentialWin] = useState(0);
    const [active, setActive] = useState(null);
    const [error, setError] = useState();
    const [present, setPresent] = useState(false);
    const [picks, setPicks] = useState(0);
    const [competition_name, setCompetitionName] = useState('');
    const [selectedTeam, setSelectedTeam] = useState();
    const [fixtures, setFixtures] = useState([]);
    const authenticated = useSelector(state => state.auth.authenticated);


    let authorization;
    if (localStorage.getItem('vsrz')) {
        if (localStorage.getItem('vsrz') === '') {
            authorization = null;
        }
        else {
            authorization = JSON.parse(localStorage.getItem('vsrz')).token
        }
    }

    let id;
    if (name === 'euro') {
        id = 4;
    } else {
        id = 9;
    }

    let height;
    present ? (
        height = '70%'
    ) : (
        height = '60%'
    )

    const getCountries = async () => {
        // console.log('countries')
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/cup/${id}`;
        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                authorization: `Token ${authorization}`
            }
        }
        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => (err));
        // console.log(response)
        setCountries(response.data);
        setTotalAmount(response.total_amount);
        setPresent(response.present);
        setSelectedTeam(response.selected);
        setCompetitionName(response.competition_name);
        setPicks(response.number_of_picks);
        setFixtures(response.selected_team_fixtures);
        setLoading(false);
    }

    useEffect(() => {
        getCountries();
    }, [])

    const submit = async () => {
        setSubmitting(true);
        let country = countries.find(country => country.country === active);

        const url = `${process.env.REACT_APP_BASE_URL}/join-cup-pool`;
        let payload = {
            competition: country.competition_id,
            id: country.country_id
        }
        const config = {
            method: "POST",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                Authorization: `Token ${authorization}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        }
        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => console.log(err));
        if (response.success) {
            // getCountries();
            window.location.reload();
            setError();
        }
        else {
            // setError(response.message)
            toast.error(response.message)
        }
        setSubmitting(false);
    }

    let selectedTeamPar;
    if (present) {
        selectedTeamPar = countries.find(country => country.country === selectedTeam)
    }

    return (
        <>
            {!present ? (
                <LayoutHeader>
                    {loading ? (

                        <div className='h-100 justify-center'>
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className="between-row" style={{ height: '8%' }}>
                                <div className={`${isMobile && 'between-row w-100'} px-3`}>
                                    <div className="d-flex flex-row">
                                        <Back />
                                        <h4 className="ms-2 seven">
                                            {/* {name === 'euro' ? 'UEFA EURO' : 'COPA AMERICA'} */}
                                            {competition_name}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            {error && <Error error={error} setError={setError} />}
                            <div className='medium text-center around-row' style={{ height: '5%' }}>
                                <div>
                                    Picks: <span className='seven'>{picks}</span>
                                </div>
                                <div>
                                    Total Amount:
                                    <span className='seven'> ₦{totalAmount}</span>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 mx-auto ' style={{ height: '87%' }}>
                                <div className='col-11 col-md-8 mx-auto' style={{ height: '30%' }}>
                                    {name === 'euro' ? (
                                        <Cup name='euro' size='100px' cuppage={true} potential_win={potential_win} present={present} total={totalAmount} selectedTeam={selectedTeam} />
                                    ) : <Cup name='copa' size='100px' cuppage={true} potential_win={potential_win} present={present} total={totalAmount} selectedTeam={selectedTeam} />
                                    }
                                </div>
                                <div className='justify-center' style={{ height: height }}>
                                    {/* {loading ? (
                                            <div className="spinner-grow" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : ( */}
                                    <div style={{ height: '100%' }} className='col-12 overflow-y-auto'>
                                        {countries.map((country, i) => {
                                            return (
                                                <CupCountry name={country.country} image={country.country_flag} total={totalAmount} key={country.country_id} picks={country.number_of_picks} setPotentialWin={setPotentialWin} active={active} setActive={setActive} present={present} />
                                            )
                                        })}
                                    </div>
                                    {/* )} */}
                                </div>
                                {!loading && (
                                    !present && (
                                        <div className='between-row px-3' style={{ height: '10%' }}>
                                            <div className='medium'>Entry Amount: ₦1000 </div>
                                            {authenticated ? (
                                                <button className='btn orange' disabled={active === null || submitting} onClick={submit}>
                                                    {submitting ? (
                                                        'Submitting'
                                                    ) : 'Submit'}
                                                </button>
                                            ) : (
                                                <LoginButton />
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        </>

                    )}
                </LayoutHeader>
            ) : (
                <JoinedCupPage country={selectedTeamPar} total={totalAmount} name={competition_name} fixtures={fixtures} />
            )}
        </>
    )
}

function CupCountry(props) {
    const { name, image, picks, total, setPotentialWin, active, setActive, present } = props;

    let multiplier;
    let agg = total + 1000;

    if (total === 0) {
        multiplier = 1;
    } else {
        multiplier = (agg / (picks+1)) / 1000;
    }

    const handleClick = () => {
        setPotentialWin((1000 * multiplier))
        setActive(name);
        console.log(agg, picks+1)
    }

    let myStyle;
    active === name && (
        myStyle = {
            backgroundColor: '#5e57f2',
            color: 'white',
        }
    )

    return (
        <div onClick={!present ? handleClick : null} style={myStyle}>
            <div className='col-9 mx-auto p-2 between-row sans'>
                <div className='d-flex flex-row align-items-center'>
                    <img src={image} height='40px' width='40px' style={{ border: '5px solid white', boxShadow: '1px 1px 2px lightgray' }} className='me-3' />
                    <div className='between-col align-items-start'>
                        <span className='seven'>
                            {name}
                        </span>
                        <span className='small'>
                            {picks} Picks
                        </span>
                    </div>
                </div>
                {!present && (
                    <div className={`bg-white border col-2 text-center py-2 rounded small ${active === name && 'text-dark'}`}>
                        x{multiplier % 1 !== 0 ? multiplier.toFixed(2) : multiplier}
                    </div>
                )}
            </div>
        </div>
    )
}

function JoinedCupPage(props) {
    const { country, total, name, fixtures=[] } = props;

    let status;
    let status_style;
    function getStatus(fixture){
        if (fixture.open && fixture.has_started) {
            status_style = {
                backgroundColor: '#33DF774D',
                color: '#00D755'
            }
            status = 'Live';
        }
        else if (!fixture.open && fixture.has_started) {
            status_style = {
                backgroundColor: '#F8333D3D',
                color: '#F8333D'
            }
            status = 'Ended';
        }
        else if (fixture.open && !fixture.has_started) {
            status_style = {
                backgroundColor: '#F790092B',
                color: '#FDB022'
            }
            status = 'Not started';
        }
        return (
            <div className='small p-2 rounded-pill' style={status_style}>{status}</div>
        )
    }

    let comp_class;
    if(name === 'Euro Championship'){
        comp_class = 'euro';
    }else{
        comp_class = 'copa';
    }

    return (
        <div className='col-12' style={{ height: '100dvh' }}>
            <div className={`col-12 create-league-box ${comp_class} p-2 text-center text-white`} style={{ height: '60%' }}>
                <div className="w-100 px-4">
                    <Navbar useLogo={true} variant='white' size='50' />
                </div>
                <div className="between-col" style={{ overflow: 'auto' }}>
                    <div className="w-100">
                        <div className=" w-100 text-start between-row">
                            <Back />
                            <div className="">
                                <b>{name}</b>
                            </div>
                            <div></div>
                        </div>
                        <div>
                            <div>
                                <div className="my-3">
                                    <h1 className="m-0"><b>₦{total/country.number_of_picks}</b></h1>
                                    <div className="small">*Potential Win</div>
                                </div>
                            </div>
                            <div className="between-col">
                                <div style={{ background: '#1F252A33' }} className='p-1 rounded-3 col-4 col-md-1'>
                                    <Picture image={country.country_flag} size='70' rounded={false} />
                                </div>
                                <h3 className="mx-0 my-2"><b>{country.country}</b></h3>
                                <div className='p-2 text-dark rounded small' style={{ background: '#F3F4F5' }}>x{total / country.number_of_picks / 1000}</div>
                                {/* <div className="small">*{leagueData.league_data.number_of_games - leagueData.league_data.played} games left</div> */}
                            </div>
                            <div>
                                {/* <h3 className="mx-0 my-2 medium"><b>Position: {leagueData.position}{getOrdinalSuffix(leagueData.position)}</b></h3>
                                {leagueData.league_data.open && (
                                    <>
                                        <div className="justify-center small"><CountdownTimer date={leagueData.end_date} /></div>
                                        <div className="p-2 rounded-pill border my-2 small w-50 mx-auto" style={status_style}>
                                            {status}
                                        </div>
                                    </>
                                )} */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='between-col align-items-start p-3 rounded-top-4 bg-white comp-detail-cover' style={{ marginTop: '-15px' }}>
                <div className='mb-3 medium seven'>
                    <span>{country.country}'s Matches</span>
                </div>
                <div className='rounded-4 col-12 p-3 border comp-detail' style={{height:'85%'}}>
                    {fixtures?.slice(0,1).map((fixture, i)=>{
                        return (
                            <div key={i} className='h-100 between-col align-items-start '>
                                <div className='small'> </div>
                                <div className='between-row col-12 sans seven'>
                                    <div className='between-row'>
                                        <Picture image={fixture.home_team_crest} rounded={false} size='35'/>
                                        <div className='ms-2'>{fixture.home_team}</div>
                                    </div>
                                    <div>{fixture.home_goals}</div>
                                </div>
                                <div className='between-row col-12 sans seven'>
                                    <div className='between-row'>
                                        <Picture image={fixture.away_team_crest} rounded={false} size='35'/>
                                        <div className='ms-2'>{fixture.away_team}</div>
                                    </div>
                                    <div>{fixture.away_goals}</div>
                                </div>
                                <div>
                                    {getStatus(fixture)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* {fixtures.map((fixture, i) => {
                    return <Forecast key={i} forecast={forecast} fixture={fixture} handleClick={() => handleClick(forecast.fixture)} />
                })} */}
            </div>
        </div>
    )
}
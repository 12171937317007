import "../index.css";
import "../styles/layout.css";
import "../styles/sidebar.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPairDetails } from "../redux/slices/ticketSlice";
import { useMediaQuery } from "react-responsive";

import OvoModal from "./OvoModal";
import { Logo } from "./layout";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import Svg from "./Svg";
import NewGameModal from "./NewGameModal";




// import def from "../images/default.png";
// import pinish from "../images/pinish.webp";
// import pool from "../images/pool.webp";
// import handshake from "../images/handshake.webp";
// import { click } from "@testing-library/user-event/dist/click";
// import { LoginButton } from "./login";
// import { getAppData } from "../redux/slices/generalSlice";
// import { Loading } from "../App";
// import { Wallet } from "../pages/profile";
// import streak_icon from "../images/fire.png";

// import { Tour, useTour } from "@reactour/tour";

// import Joyride, {
//   ACTIONS,
//   EVENTS,
//   ORIGIN,
//   STATUS,
//   CallBackProps,
// } from "react-joyride";
// import ovo from "../images/1v1.webp";
// import league from "../images/league.webp";

export default function Sidebar() {
  const [activePage, setActivePage] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [path, setPath] = useState(window.location.pathname);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const tour = useSelector((state) => state.tour.currentTour);
  const allowClick = useSelector((state) => state.tour.allowButtonClick);
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleStepChange = (newStep) => {
    console.log("Tour step changed to:", newStep);
    setCurrentStep(newStep);
    // You can perform actions based on the new step here (e.g., update UI, trigger side effects)
  };

  // useEffect(() => {
  //   if (tour == "gameTypeModal") {
  //     setTimeout(() => {
  //       setIsOpen(true);
  //     }, 1000);
  //   } else {
  //     setIsOpen(false);
  //   }
  // }, [tour]);

  function changePage(page) {
    setActivePage(page);
    let p = page.toLowerCase();
    let url = `/${p}`;
    navigate(url);
  }

  useEffect(() => {
    if (path.includes("explore") || path === "/") {
      setActivePage("Explore");
    } else if (
      path.includes("game") ||
      path.includes("league") ||
      path.includes("join-league") ||
      path.includes("join")
    ) {
      if (path != "/new-game") {
        setActivePage("Games");
      }
    } else if (
      path.includes("profile") ||
      path.includes("wallet") ||
      path.includes("deposit") ||
      path.includes("withdraw")
    ) {
      setActivePage("Profile");
    } else if (path.includes("history")) {
      setActivePage("History");
    }
  }, [path]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
    // let btn = document.querySelector(".reactour__close-button");
    // if (btn) {
    //   btn.click();
    // }
  };

  function handleClick(e) {
    const { type, ovotype, ispublic, isstreak } = e.currentTarget.dataset;

    if (ovotype == "1v1-mod") {
      closeTut("regions");
    }

    if (type === "1v1") {
      dispatch(
        setPairDetails({
          id: 0,
          stake: 0,
          number_of_games: 0,
          pairType: type,
          isPublic: ispublic,
          isStreak: isstreak,
        })
      );
      navigate(`/select/regions`);
    } else if (type === "league") {
      navigate(`/create-league`);
    } else if (type == "1v1-mod") {
      // setMessage({ message: `Game mode currently not available`, type: 'error' })
    }
  }

  return (
    
      <div
        className="sidebar"
        // style={{ height: "100%", border:'1px solid red' }}
      >

        <NewGameModal handleClick={handleClick} />

        <OvoModal handleClick={handleClick} />

        
          <Logo clickable={true} height="50" width="50" hide={true}  sidebar={true}/>
        
        
        <div className="pages sans lesp">

          <div
            data-tut="nav-step-1"
            className={`  cursor  ${
              activePage === "Explore" && "activePage"
            }`}
            onClick={() => changePage("Explore")}
          >
            {activePage === "Explore" ? (
              <Svg name="explore" state="active" />
            ) : (
              <Svg name="explore" state="inactive" />
            )}
            <span>Explore</span>
          </div>

          <div
            data-tut="nav-step-2"
            className={` cursor  ${
              activePage === "Games" && "activePage"
            }`}
            onClick={() => changePage("Games")}
          >
            {activePage !== "Games" ? (
              <Svg name="games" state="active" />
            ) : (
              <Svg name="games" state="inactive" />
            )}
            <span>Games</span>
          </div>

          <div
            onClick={() => {
              closeTut("gameTypeModal");
            }}
            data-bs-toggle="modal"
            data-bs-target="#createGameModal"
            className=" d-lg-none"
            style={{
              marginTop: "0px",
              height: "100%",
              zIndex: `${allowClick == "newGame" && tour =='intro' ? "1000" : 60}`,
            }}
            data-tut="nav-step-3"
          >
            <Svg name="newGame" />
          </div>

          <div
            data-tut="nav-step-4"
            className={` cursor  ${
              activePage === "History" && "activePage"
            }`}
            onClick={() => changePage("History")}
          >
            {activePage === "History" ? (
              <Svg name="history" state={"active"} />
            ) : (
              <Svg name="history" state={"inactive"} />
            )}
            <span>History</span>
          </div>
          <div
            data-tut="nav-step-5"
            className={` cursor  ${
              activePage === "Profile" && "activePage"
            }`}
            onClick={() => changePage("Profile")}
          >
            <Svg
              name="profile"
              state={activePage === "Profile" ? "active" : "inactive"}
            />
            <span>Profile</span>
          </div>
        </div>


        
          <div
          className="logo-sidebar"
            data-bs-toggle="modal"
            data-bs-target="#createGameModal"
            data-tut="nav-step-3.1"
            onClick={() => {
              closeTut("gameTypeModal");
            }}
          >
            <Svg name={"explore-lg"} />
          </div>
        
      </div>
    
  );
}



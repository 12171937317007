import "../index.css";
import "../styles/layout.css";

import { useEffect, useRef, useState } from "react";
import pinish from "../images/pinish.webp";
import handshake from "../images/handshake.webp";
import { useDispatch, useSelector } from "react-redux";
import streak_icon from "../images/fire.png";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import  { new1v1Steps } from "../components/steps";
import { setPairDetails } from "../redux/slices/ticketSlice";
import { useNavigate } from "react-router-dom";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";

export default function OvoModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [diableOverlay, setdiableOverlay] = useState(true);
  const currentTour = useSelector((state) => state.tour.currentTour);

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // do something
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      // setRun(false);
    }

    // console.groupCollapsed(type);
    //console.log(data);
    //eslint-disable-line no-console
    // console.groupEnd();
  };


  useEffect(() => {
    setTimeout(() => {
      if (currentTour == "new1v1") {
        setRun(true);
        console.log("running new 1v1");
      }
    }, 500);
  }, [currentTour]);

  const closeTut = (tut) => {
    let btn = document.querySelector(".reactour__close-button");
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
    if (btn) {
      btn.click();
    }
  };

  function handleClick(e) {
    const { type, ovotype, ispublic, isstreak } = e.currentTarget.dataset;
    closeTut("regions");

    if (ovotype == "1v1-mod") {
      closeTut("regions");
    }

    if (type === "1v1") {
      dispatch(
        setPairDetails({
          id: 0,
          stake: 0,
          number_of_games: 0,
          pairType: type,
          isPublic: ispublic,
          isStreak: isstreak,
        })
      );
      navigate(`/select/regions`);
    } else if (type === "league") {
      navigate(`/create-league`);
    } else if (type == "1v1-mod") {
      // setMessage({ message: `Game mode currently not available`, type: 'error' })
    }
  }

  return (
    <div
      className="modal fade"
      id="1v1Modal"
      tabIndex="-1"
      aria-labelledby="1v1ModalLabel"
      aria-hidden="true"
    >
      <Joyride
        steps={new1v1Steps}
        run={run}
        autoStart={true}
        // stepIndex={stepIndex}
        continuous={true}
        showSkipButton={false}
        allowClicksThruHole={true}
        disableOverlay={false}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        hideCloseButton={true}
        spotlightClicks={true}
      ></Joyride>

      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content p-2 p-md-4" data-tut="new-1v1-step-1">
          <div className="modal-header" style={{ border: "none" }}>
            <h1 className="modal-title fs-5" id="1v1ModalLabel">
              1v1
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div>
              

              

              <div
                className="border mb-3 between-row p-3 modes ovo-modes cursor ovo main-ovo"
                onClick={handleClick}
                data-bs-dismiss="modal"
                aria-label="Close"
                data-type="1v1"
                data-ispublic={true}
                data-isstreak={true}
                data-tut="new-1v1-step-3"
                data-ovotype="1v1-mod"
              >
                <div className="col-7">
                  <div className="mode_header">Versuz Streaks</div>
                  <div className="small">
                    Win up to ₦5,000 when you beat 3 opponents in a row 
                  </div>
                </div>
                <img src={streak_icon} width="100px" height="100px" />
              </div>

              <div
                className="border mb-3 between-row p-3 modes ovo-modes cursor ovo main-ovo"
                onClick={handleClick}
                data-bs-dismiss="modal"
                aria-label="Close"
                data-type="1v1"
                data-ispublic={true}
                data-isstreak={false}
                data-tut="new-1v1-step-2"
              >
                <div className="col-7">
                  <div className="mode_header">Find random opponent</div>
                  <div className="small">
                    Anyone can join your game from the explore page
                  </div>
                </div>
                <img src={pinish} width="100px" height="100px" />
              </div>

              <div
                className="border mb-3 between-row p-3 modes ovo-modes cursor ovo main-ovo"
                onClick={handleClick}
                data-bs-dismiss="modal"
                aria-label="Close"
                data-type="1v1"
                data-ispublic={false}
                data-isstreak={false}
                // data-tut="new-1v1-step-1"
              >
                <div className="col-7">
                  <div className="mode_header">Connect with a friend</div>
                  <div className="small">
                    Your game will only be visible to people you share it with
                  </div>
                </div>
                <img src={handshake} width="100px" height="100px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// fixturesSteps
import TutorialComponent from "./TutorialComponent";

export default [
    {
      target: '[data-tut="select-fixture-step-0"]',
      content: (
        <TutorialComponent
          image={null}
          text={
            <p>
              <b>Click</b> on a match to see the available options{" "}
            </p>
          }
        />
      ),
      disableBeacon: true,
      placement: " bottom",
      offset: 0,
      hideFooter: true,
    },
  ];
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// tours => intro, navigation, create a 1v1, select games

export const tourSlice = createSlice({
  name: "tour",
  initialState: {
    tourOn: true,
    // currentTour: "intro",
    currentTour: "main",
    allowButtonClick: "none",
  },
  reducers: {
    setCurrentTour: (state, action) => {
      const sequence = localStorage.getItem("sequence");
      console.log(action.payload)

      if (!sequence) {
        let newSequence = [];
        newSequence.push(action.payload);
        let data = JSON.stringify(newSequence);
        localStorage.setItem("sequence", data);
        console.log(data);

        
        return {
          ...state,
          currentTour: action.payload,
        };
        
      } else {
        let newSequence = JSON.parse(sequence);
        if (!newSequence.includes(action.payload)) {
          localStorage.setItem("tour", action.payload);
          newSequence.push(action.payload);
          let data = JSON.stringify(newSequence);
          localStorage.setItem("sequence", data);
          return {
            ...state,
            currentTour: action.payload,
          };
        }
      }
    },
    setAllowButtonClick: (state, action) => {
      return {
        ...state,
        allowButtonClick: action.payload,
      };
    },
  },
});

export const { setCurrentTour, setAllowButtonClick } = tourSlice.actions;
export default tourSlice.reducer;

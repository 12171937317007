import { useEffect, useState } from "react";
import { LayoutHeader } from "../components/layout";
import "../index.css";
import "../styles/select.css";
import "../styles/ticket.css";
import premier_league_logo from "../images/premier_league_logo.webp";
import { useNavigate, useParams } from "react-router-dom";
import {  NoCompetition } from "./fixtures";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../App";
import { setCurrentRegion, setCompetitions } from "../redux/slices/selectSlice";
import {
  getTicketCount,
  clearStoreTicket,
  deleteSelectedOptions,
} from "../redux/slices/ticketSlice";
import Login, { LoginButton } from "../components/login";
import { setTicketData } from "../redux/slices/generalSlice";
import { useMediaQuery } from "react-responsive";
import { Error } from "./profile";
import { NoFixture } from "./fixtures";
import toast from "react-hot-toast";
import { Competitions } from "../components/Competitions";
import Fixture from "../components/fixture";

import { Tour, useTour } from "@reactour/tour";
import {
  regionsSteps,
  competitonsSteps,
  selectionSteps,
} from "../components/steps";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import { useLocation } from 'react-router-dom';

export default function Regions({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.general.countries);
  const loading = useSelector((state) => state.general.loading);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();

  function handleClick(name, logo, id) {
    navigate(`/select/competitions/${id}`);
    dispatch(setCurrentRegion({ name, logo, id }));
    closeTut("competitions");
  }

  const tour = useSelector((state) => state.tour.currentTour);
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [diableOverlay, setdiableOverlay] = useState(true);
  const currentTour = useSelector((state) => state.tour.currentTour);

  const handleStepChange = (newStep) => {
    console.log("Tour step changed to:", newStep);
    setCurrentStep(newStep);
    // You can perform actions based on the new step here (e.g., update UI, trigger side effects)
  };

  useEffect(()=>{
    console.log(window.location.href)
  },[])

  useEffect(() => {
    if (currentTour == "regions") {
      setTimeout(() => {
        setRun(true);
        // document.querySelector('.modal-backdrop.show').style.display= "none"
      }, 500);
    } else {
      setRun(false);
    }
  }, [currentTour]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  return (
    <LayoutHeader>
      {loading ? (
        <Loading height="70dvh" />
      ) : (
        <>
          <Joyride
            steps={regionsSteps}
            run={run}
            autoStart={true}
            showProgress={true}
            // stepIndex={stepIndex}
            continuous={true}
            showSkipButton={false}
            allowClicksThruHole={true}
            disableOverlay={false}
            disableOverlayClose={false}
            disableBodyFocus={false}
            spotlightClicks={true}
            // callback={handleJoyrideCallback}
            hideCloseButton={true}
          ></Joyride>

          <div className="between-row regions-nav ">
            <div className={`between-row w-100 px-3`}>
              <div className="d-flex flex-row">
              {location.pathname == '/select/regions' &&
            
            // <p style={{display:'flex',gap:'1rem', alignItems:'center'}}>  <Back /> <b>Back  </b> </p>
                <Back />
          }
                <h4 className="ms-2 seven">Pick Games</h4>
              </div>
              <div className="d-lg-none">

               <ViewSelections />
              </div>
            </div>
          </div>


          <div className=" me-0 regions-cont "  >

            <div className=" pe-0 cont-rg-1" data-tut={`select-region-step-0`}>
              <ColumnHeader name="Regions" />
              
                {countries?.map((country, i) => {
                  return (
                    <div
                    className="sans seven"
                      style={{ position: "relative" }}
                      key={i}
                      // data-tut={`select-region-step-${i}`}
                      onClick={(e) =>
                        handleClick(
                          country.country,
                          country.country_flag,
                          country.id
                        )
                      }
                    >
                      <Country
                        name={country.country}
                        image={country.country_flag}
                      />
                    </div>
                  );
                })}
              
            </div>

            <div className="col-6 d-none d-lg-block overflow-y-auto cont-rg-2">
            {location.pathname != '/select/regions' &&
            
              <p style={{display:'flex',gap:'1rem', alignItems:'center'}}>  <Back /> <b>Back  </b> </p>
            }
              {children ? children : <QuickPicks />}
            </div>

            <div className="col-3 d-none d-lg-block cont-rg-3">
              <ColumnHeader name="Selections" />
              
                <div style={{ height: "auto" }}>
                  <Selections />
                </div>
              
            </div>

          </div>
        </>
      )}
    </LayoutHeader>
  );
}

function QuickPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [pairDetails, setPairDetails] = useState();

  // console.log(fixtures)

  const getFixtures = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/fixtures/quick_picks`;

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);

    if (response.success) {
      let fixtures = JSON.parse(localStorage.getItem("fixtures"));
      if (!fixtures) {
        let obj = {};
        response.data.forEach((item) => {
          obj[item.id] = item;
        });
        localStorage.setItem("fixtures", JSON.stringify(obj));
      } else {
        let obj = fixtures;
        response.data.forEach((item) => {
          obj[item.id] = item;
        });
        localStorage.setItem("fixtures", JSON.stringify(obj));
      }
      setFixtures(response.data);
      setLoading(false);
    } else {
    }
  };

  useEffect(() => {
    getFixtures();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("pairDetails")) {
      localStorage.setItem("pairDetails", JSON.stringify(pairDetails));
    }
    if (JSON.stringify(pairDetails) != localStorage.getItem("pairDetails")) {
      const details = JSON.parse(localStorage.getItem("pairDetails"));
      setPairDetails(details);
    }
  }, []);

  useEffect(() => {
    if (pairDetails?.max_date) {
      let new_fixtures = fixtures.filter((fixture) => {
        return fixture.date <= pairDetails.max_date;
      });
      setFilteredFixtures(new_fixtures);
    }
  }, [pairDetails, fixtures]);

  return (
    <>
      <ColumnHeader name="QuickPicks" />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div style={{ height: "80dvh", overflow: "auto" }} className="sans">
            {pairDetails &&
            pairDetails.pairType === "league" &&
            pairDetails.max_date ? (
              filteredFixtures.length > 0 ? (
                filteredFixtures.slice(0, 15).map((fixture, i) => {
                  return (
                    <div key={i} className="mb-1">
                      <Fixture fixture={fixture} />
                    </div>
                  );
                })
              ) : (
                <NoFixture />
              )
            ) : fixtures.length > 0 ? (
              fixtures.slice(0, 15).map((fixture, i) => {
                return (
                  <div key={i} className="mb-1">
                    <Fixture fixture={fixture} />
                  </div>
                );
              })
            ) : (
              <NoFixture />
            )}
          </div>
        </>
      )}
    </>
  );
}

function Country(props) {
  const { name, image } = props;
  return (
    <div
      className="between-row px-4 border-bottom cursor country"
      style={{ height: "60px" }}
    >
      <div className="between-row">
        <img src={image} width="25px" height="25px" />
        <div className="ms-2">{name}</div>
      </div>
      <Arrow />
    </div>
  );
}



export function Arrow() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: "none" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0316 17.5856C9.70811 17.3269 9.65567 16.8549 9.91442 16.5315L13.5396 12L9.91442 7.46849C9.65566 7.14505 9.70811 6.67308 10.0316 6.41432C10.355 6.15556 10.827 6.208 11.0857 6.53145L15.0857 11.5315C15.3049 11.8054 15.3049 12.1946 15.0857 12.4685L11.0857 17.4685C10.827 17.7919 10.355 17.8444 10.0316 17.5856Z"
        fill="#141B20"
        // className="arrow-icon"
      />
    </svg>
  );
}

export function Back(props) {
  // const { variant } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        window.history.back();
      }}
      data-tut="back"
    >
      <rect width="32" height="32" rx="8" fill="#F0F0F0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.958 8.88576C19.3893 9.23077 19.4592 9.86006 19.1142 10.2913L14.2806 16.3333L19.1142 22.3753C19.4592 22.8065 19.3893 23.4358 18.958 23.7808C18.5268 24.1258 17.8975 24.0559 17.5525 23.6247L12.2191 16.958C11.927 16.5928 11.927 16.0738 12.2191 15.7086L17.5525 9.04193C17.8975 8.61067 18.5268 8.54075 18.958 8.88576Z"
        fill="#141B20"
      />
    </svg>
  );
}

export function ViewSelections() {
  const count = useSelector((state) => state.ticket.selectedOptionsCount);
  const selections = useSelector((state) => state.ticket.selectedOptions);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    dispatch(getTicketCount());
  }, [selections]);

  const clearTicket = () => {
    localStorage.setItem("ticket", "{}");
    dispatch(clearStoreTicket());
  };

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  const modalAttributes = isMobile && {
    "data-bs-toggle": "modal",
    "data-bs-target": "#selectionsModal",
  };

  return (
    <div
      className="between-row  "
      style={{ paddingTop: "15px", paddingBottom: "15px" }}
    >
      <div
        className="modal fade"
        id="selectionsModal"
        tabIndex="-1"
        aria-labelledby="selectionsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="selectionsModalLabel">
                Your picks
              </h1>
              {/* <ViewSelections /> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Selections active_modal={true} />
            </div>
          </div>
        </div>
      </div>

      <div className="cursor" onClick={clearTicket} data-tut="clear-ticket">
        <svg
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ pointerEvents: "none" }}
        >
          <path
            d="M7.111 10.6667V24.0001C7.111 26.9456 9.49882 29.3334 12.4443 29.3334H20.4443C23.3899 29.3334 25.7777 26.9456 25.7777 24.0001V10.6667M19.111 14.6667V22.6667M13.7777 14.6667L13.7777 22.6667M21.7777 6.66675L19.9026 3.85421C19.4081 3.11235 18.5755 2.66675 17.6838 2.66675H15.2048C14.3132 2.66675 13.4806 3.11235 12.986 3.85421L11.111 6.66675M21.7777 6.66675H11.111M21.7777 6.66675H28.4443M11.111 6.66675H4.44434"
            stroke="#141B20"
            strokeWidth="1.94444"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="cursor"
          />
        </svg>
      </div>
      <div
        data-tut="view-selections"
        onClick={() => closeTut("selections")}
        className={`rounded-circle justify-center small ms-1 sans view-selections-btn ${
          count == 10 && "active"
        }`}
        {...modalAttributes}
      >
        {count}
      </div>
    </div>
  );
}

export function ColumnHeader(props) {
  const { name, image, svg } = props;
  let myClass = "between-row";
  return (
    <div
      className={`my-1 px-2 mb-3 align-row-center ${
        (name === "Regions" || name === "Selections") && myClass
      } ${svg && "justify-content-end"}`}
    >
      {image && <img src={image} height="25px" width="25px" className="me-2" />}
      <b>{name}</b>
      {svg && (
        <div className="w-100 mt-3">
          <button
            className="btn w-100 insight"
            data-bs-toggle="modal"
            data-bs-target="#fixtureStatModal"
          >
            
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9192 2.34417C10.3071 1.15999 8.32771 0.672604 6.3526 0.977707C4.37722 1.27893 2.63338 2.33513 1.45155 3.94594C-0.994369 7.27496 -0.275611 11.9715 3.05329 14.4136C4.38741 15.3935 5.93878 15.8628 7.47842 15.8628C9.78214 15.8628 12.0588 14.804 13.5249 12.8118C15.9708 9.48277 15.2494 4.78625 11.9232 2.34417H11.9192ZM10.6613 12.0049L9.46935 10.4626L10.3329 7.85512L11.8856 7.12987L14.272 8.88418C14.1931 9.98303 13.8454 11.0483 13.2494 11.9946L10.6652 12.0075L10.6613 12.0049ZM4.36928 12.0049L1.72167 11.992C1.10631 11.0056 0.754671 9.88349 0.692617 8.7394L2.91359 7.14412L4.79329 7.8461L5.62067 10.4446L4.36666 12.0101L4.36928 12.0049ZM12.5242 3.80995L11.64 6.4834L10.0873 7.20864L7.90644 5.60691L7.84956 3.60827L10.0046 2.05952C10.5347 2.27153 11.0375 2.54949 11.5068 2.89722C11.8779 3.17129 12.2178 3.47638 12.5229 3.8112L12.5242 3.80995ZM5.02092 2.03625L7.16564 3.61214L7.22252 5.61078L5.02223 7.19312L3.14253 6.49114L2.3281 3.94309C3.06241 3.08599 3.98285 2.437 5.02223 2.03367L5.02092 2.03625ZM5.7229 14.9473L4.91492 12.4186L6.16892 10.8531L8.9122 10.866L10.1067 12.4122L9.3052 14.924C8.143 15.2446 6.90194 15.2614 5.72167 14.9434L5.7229 14.9473Z" fill="#141B20"/>
</svg>
            Match insights

          </button>
        </div>
      )}
      {name === "Regions" && (
        // <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
        //     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        // </svg>
        <></>
      )}
      {name === "Selections" && <ViewSelections />}
    </div>
  );
}

function Selections(props) {
  const [selectionsArray, setSelectionsArray] = useState([]);
  const [totalOdds, setTotalOdds] = useState(0);
  const [stake, setStake] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pairDetails, setPairDetails] = useState({
    id: 0,
    stake: 0,
    number_of_games: 0,
  });
  const [error, setError] = useState(null);
  const ticket = useSelector((state) => state.ticket);
  const storeSelections = useSelector((state) => state.ticket.selectedOptions);
  const storeGames = useSelector((state) => state.ticket.games);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { active_modal = false } = props;

  useEffect(() => {
    const object = JSON.parse(JSON.stringify(storeSelections));
    let result = [];

    for (let key in object) {
      for (let key2 in object[key]) {
        result.push(`${key}|${key2}|${object[key][key2]}`);
      }
    }

    setSelectionsArray(result);
  }, [storeSelections]);

  useEffect(() => {
    let total = 1;
    if (selectionsArray.length > 0) {
      selectionsArray.forEach((item) => {
        let arr = item.split("|");
        let choice = arr[2].split("-");
        total *= choice[1];
      });
      setTotalOdds(total.toFixed(2));
    } else {
      setTotalOdds(0);
    }
  }, [selectionsArray]);

  useEffect(() => {
    if (!localStorage.getItem("pairDetails")) {
      localStorage.setItem("pairDetails", JSON.stringify(pairDetails));
    }
    if (JSON.stringify(pairDetails) != localStorage.getItem("pairDetails")) {
      const details = JSON.parse(localStorage.getItem("pairDetails"));
      // console.log(details)
      // if (details.pairType === "league") {
      //     setButtonText("Join League")
      // }
      setPairDetails(details);
    }
  }, []);

  useEffect(() => {}, [pairDetails]);

  const streak = pairDetails?.isStreak === "true";

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [diableOverlay, setdiableOverlay] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const currentTour = useSelector((state) => state.tour.currentTour);

  const handleStepChange = (newStep) => {
    console.log("Tour step changed to:", newStep);
    setCurrentStep(newStep);
    // You can perform actions based on the new step here (e.g., update UI, trigger side effects)
  };

  useEffect(() => {
    if (currentTour == "selections") {
      setTimeout(() => {
        setRun(true);
        // document.querySelector('.modal-backdrop.show').style.display= "none"
      }, 500);
    } else {
      setRun(false);
    }
  }, [currentTour]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  const placeBet = async () => {
    setLoading(true);
    const data = ticket.selectedOptions;

    const arr = {};
    for (let key in data) {
      arr[key] = [];
      for (let key2 in data[key]) {
        arr[key].push(data[key][key2].split("-")[0]);
      }
    }

    // pairing and existing game
    let url = `${process.env.REACT_APP_BASE_URL}/games/join_versus`;
    let payload = {
      betcode_id: pairDetails.id,
      data: arr,
      amount: pairDetails.stake,
      streak: pairDetails.isStreak,
    };

    // creating a new game
    if (pairDetails.id === 0) {
      url = `${process.env.REACT_APP_BASE_URL}/games/versus`;
      payload = {
        data: arr,
        amount: stake,
        public: pairDetails.isPublic,
        streak: pairDetails.isStreak,
      };
    }

    // ------------ League ----------------------
    if (pairDetails.pairType === "league") {
      url = `${process.env.REACT_APP_BASE_URL}/games/join_league`;
      payload = {
        league_id: pairDetails.id,
        data: arr,
        amount: pairDetails.stake,
        streak: pairDetails.isStreak,
      };
    }

    const config = {
      method: "POST",
      headers: {
        Authorization: `Token ${
          JSON.parse(localStorage.getItem("vsrz")).token
        }`,
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => console.log(err));
    // setMessage({ message: err, type: 'error' })

    if (response.success) {
      if (pairDetails.pairType === "league") {
        navigate(`/league/${response.pair_code}`);
      } else {
        dispatch(setTicketData(response.betcode_data));
        window.location.href = `/ticket/${response.betcode_data.id}`;
      }
    } else {
      setError(response.message);
    }
    setLoading(false);
  };

  function handleStakeChange(e) {
    setStake(e.target.value);
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // do something
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      setRun(false);
    }

    // console.groupCollapsed(type);
    //console.log(data); 
    //eslint-disable-line no-console
    // console.groupEnd();
  }

  return (
    <>
      
          <Joyride
            steps={selectionSteps}
            showProgress={true}
            run={run}
            autoStart={true}
            stepIndex={stepIndex}
            continuous={true}
            showSkipButton={false}
            allowClicksThruHole={true}
            disableOverlay={false}
            disableOverlayClose={false}
            disableBodyFocus={false}
            spotlightClicks={true}
            callback={handleJoyrideCallback}
            locale={{last: "Finish"}}
            hideCloseButton={true}
          ></Joyride>

      <div className=" selections sans" style={{ overflowY: "auto" }}>
        {error && !loading && <Error error={error} setError={setError} />}
        {selectionsArray.map((selection, i) => {
          return (
            <Selection
              key={selection}
              index={i}
              data={selection}
              games={storeGames}
            />
          );
        })}
      </div>
      <div className=" pt-3 sans" >
        <div className=" text-end" data-tut="selections-step-0">Total odds: {totalOdds}</div>
        {!streak && <span className="medium">Enter stake</span>}
        <div className="between-row" data-tut="selections-step-1">
          {!streak && (
            <div className="me-1 w-100">
              <div className="border p-1 align-row-center w-100 rounded">
                <span className="me-1">
                  {pairDetails.points ? (
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 26 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        d="M0.00939941 8.85865V25.4124L12.9147 33.6892L25.82 25.4124V8.85865L12.9147 0.581787L0.00939941 8.85865Z"
                        fill="#ECB20C"
                      />
                      <path
                        d="M8.22915 14.2778L12.9147 4.1516V0.581787L0.00939941 8.85865L8.22915 14.2778Z"
                        fill="url(#paint0_linear_2712_29133)"
                      />
                      <path
                        d="M19.0201 20.5166L12.9148 30.1192V33.6891L25.8201 25.4122L23.0369 23.6272L19.0201 20.5166Z"
                        fill="url(#paint1_linear_2712_29133)"
                      />
                      <path
                        d="M12.6915 25.8801L2.79241 23.6274L0.00939941 25.4124L12.9147 33.6893V30.1195L12.6915 25.8801Z"
                        fill="url(#paint2_linear_2712_29133)"
                      />
                      <path
                        d="M13.1353 8.54164L23.0369 10.6436L25.8201 8.85865L12.9148 0.581787V4.1516L13.1353 8.54164Z"
                        fill="url(#paint3_linear_2712_29133)"
                      />
                      <path
                        d="M8.67527 20.0789L3.21023 10.9351L0.00939941 8.85864V25.4124L2.79241 23.6274L8.67527 20.0789Z"
                        fill="url(#paint4_linear_2712_29133)"
                      />
                      <path
                        d="M17.1539 20.0789L22.619 10.9351L25.82 8.85864V25.4124L23.0368 23.6274L17.1539 20.0789Z"
                        fill="url(#paint5_linear_2712_29133)"
                      />
                      <g filter="url(#filter0_i_2712_29133)">
                        <path
                          d="M2.7926 10.6437V23.6274L12.9149 30.1194L23.037 23.6274V10.6437L12.9149 4.15161L2.7926 10.6437Z"
                          fill="url(#paint6_diamond_2712_29133)"
                        />
                      </g>
                      <path
                        d="M5.57699 10.6885L5.82116 9.71166C5.85245 9.58635 5.91734 9.4718 6.00871 9.38042C6.10009 9.28904 6.21464 9.22415 6.33995 9.19286L7.31679 8.9487C7.34311 8.94208 7.34311 8.90466 7.31679 8.89821L6.33995 8.65404C6.21464 8.62275 6.10009 8.55786 6.00871 8.46649C5.91734 8.37511 5.85245 8.26056 5.82116 8.13525L5.57699 7.15841C5.57037 7.13209 5.53296 7.13209 5.5265 7.15841L5.28234 8.13525C5.25105 8.26056 5.18616 8.37511 5.09478 8.46649C5.00341 8.55786 4.88885 8.62275 4.76354 8.65404L3.78671 8.89821C3.76039 8.90483 3.76039 8.94224 3.78671 8.9487L4.76354 9.19286C4.88885 9.22415 5.00341 9.28904 5.09478 9.38042C5.18616 9.4718 5.25105 9.58635 5.28234 9.71166L5.5265 10.6885C5.53313 10.7148 5.57054 10.7148 5.57699 10.6885Z"
                        fill="white"
                      />
                      <path
                        d="M22.9739 21.4029L23.157 20.6702C23.1805 20.5762 23.2292 20.4903 23.2977 20.4217C23.3663 20.3532 23.4522 20.3045 23.5462 20.281L24.2789 20.0978C24.2986 20.0928 24.2986 20.0648 24.2789 20.0599L23.5462 19.8766C23.4522 19.8531 23.3663 19.8044 23.2977 19.7359C23.2292 19.6674 23.1805 19.5815 23.157 19.4874L22.9739 18.7548C22.969 18.7351 22.941 18.7351 22.936 18.7548L22.753 19.4874C22.7294 19.5815 22.6808 19.6674 22.6122 19.7359C22.5437 19.8044 22.4578 19.8531 22.3638 19.8766L21.6311 20.0599C21.6114 20.0648 21.6114 20.0928 21.6311 20.0978L22.3638 20.281C22.4578 20.3045 22.5437 20.3532 22.6122 20.4217C22.6808 20.4903 22.7294 20.5762 22.753 20.6702L22.936 21.4029C22.941 21.4226 22.969 21.4226 22.9739 21.4029Z"
                        fill="white"
                      />
                      <path
                        d="M22.1269 15.2446L21.1453 16.9684L21.0734 17.1121H17.3385L16.9555 17.8064H20.6664L20.0918 18.788L19.5891 19.6499H15.8781L14.8486 21.4455L13.3642 23.9833H9.74907L9.29418 21.4455L8.98294 19.6499H5.63112L5.48747 18.788L5.31988 17.8064H8.64775L8.52805 17.1121H5.17623L5.15229 16.9684L4.86499 15.2446H8.19287L7.78586 12.8744L7.47462 11.1746H11.6165L11.9038 12.8744L12.3348 15.2446L12.5023 16.2502L12.6699 17.1121L12.7657 17.7106L13.1009 17.1121L13.6037 16.2502L14.1783 15.2446L15.5669 12.8744L16.5245 11.1746H20.7622L19.8045 12.8744L18.4159 15.2446H22.1269Z"
                        fill="black"
                      />
                      <defs>
                        <filter
                          id="filter0_i_2712_29133"
                          x="2.7926"
                          y="4.15161"
                          width="20.3503"
                          height="26.0737"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="0.105927" dy="0.105927" />
                          <feGaussianBlur stdDeviation="0.222446" />
                          <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                          />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.529412 0 0 0 0 0.713726 0 0 0 0 0.0196078 0 0 0 1 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_2712_29133"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear_2712_29133"
                          x1="6.5028"
                          y1="4.77067"
                          x2="8.42669"
                          y2="7.14785"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#D3FF58" />
                          <stop offset="1" stopColor="#ACE805" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_2712_29133"
                          x1="18.9703"
                          y1="28.9785"
                          x2="17.5808"
                          y2="26.8448"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#D3FF58" />
                          <stop offset="1" stopColor="#ACE805" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_2712_29133"
                          x1="6.2871"
                          y1="29.7353"
                          x2="8.60171"
                          y2="27.2939"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#CAFF37" />
                          <stop offset="1" stopColor="#ACE805" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_2712_29133"
                          x1="19.4922"
                          y1="4.59325"
                          x2="17.1771"
                          y2="7.06458"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#CAFF37" />
                          <stop offset="1" stopColor="#86B504" />
                        </linearGradient>
                        <linearGradient
                          id="paint4_linear_2712_29133"
                          x1="-1.14256"
                          y1="17.1328"
                          x2="0.675385"
                          y2="18.9862"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#CAFF37" />
                          <stop offset="1" stopColor="#ACE805" />
                        </linearGradient>
                        <linearGradient
                          id="paint5_linear_2712_29133"
                          x1="25.82"
                          y1="17.1355"
                          x2="22.6319"
                          y2="17.1355"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#BDFF05" />
                          <stop offset="1" stopColor="#86B504" />
                        </linearGradient>
                        <radialGradient
                          id="paint6_diamond_2712_29133"
                          cx="0"
                          cy="0"
                          r="1"
                          gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(12.9062 16.4708) rotate(-90) scale(13.9956 13.9956)"
                        >
                          <stop stopColor="#BDFF05" />
                          <stop offset="1" stopColor="#D3FF58" />
                        </radialGradient>
                      </defs>
                    </svg>
                  ) : (
                    "₦"
                  )}
                </span>
                {pairDetails.pairType === "1v1" ? (
                  <input
                    className="medium w-100 fcs"
                    style={{ border: "none" }}
                    value={stake}
                    type="number"
                    onChange={(e) => handleStakeChange(e)}
                  />
                ) : (
                  <input
                    type="number"
                    className="medium w-100 fcs"
                    style={{ border: "none" }}
                    value={
                      pairDetails.points
                        ? pairDetails.entry_points
                        : pairDetails.stake
                    }
                    readOnly
                  />
                )}
              </div>
            </div>
          )}
          <div className={`${streak ? "w-100" : "w-50"} text-end`}>
            {authenticated ? (
              <button
                className={`btn orange ${loading && "btn-secondary"} w-100`}
                onClick={placeBet}
                disabled={loading}
                
              >
                {loading ? "loading..." : "continue"}
              </button>
            ) : (
              <LoginButton active_modal={active_modal} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function Selection(props) {
  const { data, games, def = true } = props;
  const [fixtureData, setFixtureData] = useState(null);
  const [fixtureId, setFixtureId] = useState("");
  const [group, setGroup] = useState("");
  const [optionString, setOptionString] = useState("");
  const [option, setOption] = useState("");
  const [odd, setOdd] = useState("");
  const [won, setWon] = useState();
  const dispatch = useDispatch();

  const updateTicketAction = (e) => {
    const { id, group, option } = e.target.dataset;

    const payload = { fixtureId: id, group, option };

    dispatch(deleteSelectedOptions(payload));
  };

  const getFixtureData = async (fixtureId) => {
    let stored = JSON.parse(localStorage.getItem("fixtures"));

    if (!stored) {
      const url = `${process.env.REACT_APP_BASE_URL}/get_fixture/${fixtureId}`;

      const config = {
        method: "GET",
        headers: {
          reactkey: process.env.REACT_APP_AUTH_KEY,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(user),
      };

      const response = await fetch(url, config)
        .then((data) => data.json())
        .catch((err) => err);

      if (response.success) {
        setFixtureData(response.fixture_data);
      }
    } else {
      setFixtureData(stored[fixtureId]);
    }
  };

  useEffect(() => {
    if (def) {
      const array = data.split("|");
      setFixtureId(Number(array[0]));

      getFixtureData(Number(array[0]));

      setGroup(array[1].toUpperCase());
      setOptionString(array[2]);
      setOption(array[2].split("-")[0]);
      setOdd(array[2].split("-")[1]);
    } else {
      setFixtureData(data.fixture);
      setOption(data.forecast);
      setOdd(data.odd);
      setWon(data.won);
    }
  }, []);

  return (
    <div className="between-row border-bottom mb-2 medium p-1 position-relative">
      {fixtureData && (
        <>
          <div className="col-7">
            <div className="align-row-center">
              <img
                src={fixtureData.home_team_crest}
                height="20px"
                width="20px"
              />
              <span className="ms-1">{fixtureData.home_team}</span>
            </div>
            <div className="align-row-center mt-1">
              <img
                src={fixtureData.away_team_crest}
                height="20px"
                width="20px"
              />
              <span className="ms-1">{fixtureData.away_team}</span>
            </div>
          </div>
          <div className="around-row border-start col-5">
            <div className="justify-center flex-column col-8 small mx-2">
              <div className="p-2 rounded selection col-12 text-center">
                <span>{option}</span>
              </div>
              <div>{odd}</div>
            </div>
            {def && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="red"
                className="bi bi-x-circle cursor"
                viewBox="0 0 16 16"
                onClick={updateTicketAction}
                data-id={fixtureId}
                data-group={group}
                data-option={optionString}
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            )}
          </div>
        </>
      )}
      {!def && (
        <div
          className={`rounded-circle ${
            won ? "bg-success" : "bg-danger"
          } position-absolute`}
          style={{ width: "10px", height: "10px", right: "0" }}
        ></div>
      )}
    </div>
  );
}

export function TicketPage() {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const data = useSelector((state) => state.general.ticketData);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      if (id != data.id) {
        getGames();
      } else {
        setGames(data);
      }
    } else {
      getGames();
    }
  }, [authenticated]);

  const getGames = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BASE_URL}/new_betcode/${id}`;
    const authToken = localStorage.getItem("vsrz")
      ? `Token ${JSON.parse(localStorage.getItem("vsrz")).token}`
      : "Token ";

    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => {
        navigate("/explore");
      });

    if (response.success) {
      setGames(response.data);
    } else {
      navigate(`/join/${id}`);
    }
    setLoading(false);
  };

  function handleCopy(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const invite = () => {
    let link = `https://app.versuz.co/join/${id}`;
    if (navigator.share) {
      navigator.share({
        url: `${link}`,
        text: `Join me in a thrilling 1v1 showdown on Versuz! Select ${
          games.fixtures.length
        } predictions and let's battle it out. Entry is ${
          games.stake === 0 ? "free" : "available for a stake of " + games.stake
        }`,
      });
    } else {
      navigator.clipboard.writeText(link);
      toast.success("1v1 invite link copied");
    }
  };

  const tour = useSelector((state) => state.tour.currentTour);
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleStepChange = (newStep) => {
    console.log("Tour step changed to:", newStep);
    setCurrentStep(newStep);
    // You can perform actions based on the new step here (e.g., update UI, trigger side effects)
  };

  useEffect(() => {
    if (tour == "match") {
      setTimeout(() => {
        setIsOpen(true);
      }, 1000);
    } else {
      setIsOpen(false);
    }
  }, [tour]);

  return (
    <div className="ticket-body justify-center">
      {loading ? (
        <Loading />
      ) : (
        <div className="col-10 col-md-8 col-lg-4 rounded p-3 ticket">
          <div className="between-row sans">
            <div>Your Picks</div>
            <div>
              <b>{games.league ? "league" : games.streak ? "Streak" : "1v1"}</b>
            </div>
          </div>
          <div className="my-2" style={{ height: "80dvh" }}>
            <div
              className="sans tickets"
              style={{ overflowY: "auto", height: "62dvh" }}
            >
              {games.fixtures?.map((fixture, i) => {
                return <Selection key={i} data={fixture} def={false} />;
              })}
            </div>
            <div className="mt-2 ticket-btn">
              <div className="text-end medium py-2" mt-2 mb-2>
                Total odds: <b>{games.odds}</b>
              </div>
              <div className="text-center py-2">
                {/* <div
                  className="text-center cursor position-relative copy "
                  onClick={() => handleCopy(games.code)}
                >
                  {!copied ? "Copy Betcode" : "Copied"}
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-1"
                  >
                    <path
                      d="M4.48016 4.50323V3.21557C4.48016 1.79327 5.63317 0.640259 7.05548 0.640259L10.9185 0.640259C12.3408 0.640259 13.4938 1.79327 13.4938 3.21557V7.07855C13.4938 8.50086 12.3408 9.65386 10.9185 9.65386H9.63079M4.48016 4.50323H3.1925C1.7702 4.50323 0.617188 5.65624 0.617188 7.07855V10.9415C0.617188 12.3638 1.7702 13.5168 3.1925 13.5168H7.05548C8.47779 13.5168 9.63079 12.3638 9.63079 10.9415V9.65386M4.48016 4.50323H7.05548C8.47779 4.50323 9.63079 5.65624 9.63079 7.07855V9.65386"
                      stroke="#141B20"
                      strokeWidth="1.15889"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div> */}
                {/* <div> */}
                  {!games.ended && !games.paired && !games.streak && (
                    <button
                      className="btn text-light sans"

                      onClick={invite}
                      style={{ background: "#5e57f2",width: "100%", maxWidth: "400px"  }}
                    >
                      Invite a player
                    </button>
                  )}
                {/* </div> */}
              </div>
              <div className="text-center py-2">
                <button
                  className="btn orange sans"
                  style={{ width: "100%", maxWidth: "400px" }}
                  onClick={() => (window.location.href = "/games")}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import Layout from './components/layout';
import Explore from './pages/explore';
import Games from "./pages/games";
import History, { getHistory } from "./pages/history";
import Profile from "./pages/profile";
import Regions, {  TicketPage } from "./pages/select";
import { Competitions } from "./components/Competitions";
import Fixtures from "./pages/fixtures";
import FixtureDetails from "./components/FixtureDetails";
import Login, { Signup, VerifyEmail, FindAccount, ChangePassword } from "./components/login";
import { useDispatch, useSelector } from "react-redux";
import { setHistory, setHistoryLoading, getAppData, setBanks } from "./redux/slices/generalSlice";
import { setSelectedOptions } from "./redux/slices/ticketSlice";
import Join from "./components/join";
import { CreateLeague, InLeague, JoinLeague } from "./pages/league";
import Game from "./pages/game";
import { Ask } from "./firebase/firebaseConfig";
import toast, { Toaster } from "react-hot-toast";
import Tournaments from "./pages/tournaments";
import { setWeeklyTournament, setTournamentLoading } from "./redux/slices/generalSlice";
import { Terms } from "./pages/agreements";
import { CupPage } from "./pages/cups";
import { setCurrentTour } from "./redux/slices/tourSlice";

export function sortedArray(array) {
  return array.slice().sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    // Compare the dates in reverse order
    return dateB - dateA;
  });
}

export function SortedArrayEndDate(array) {
  return array.slice().sort((a, b) => {
    const dateA = new Date(a.ended_at);
    const dateB = new Date(b.ended_at);

    // Compare the dates in reverse order
    return dateB - dateA;
  });
}

export const Loading = (props) => {
  const { height = '65dvh' } = props;
  return (
    <div className="loading" style={{ height: height }}>
      <svg
        width="99"
        height="64"
        viewBox="0 0 99 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M95.4074 20.3992L99 0L49.5119 19.2356L0 0L3.61644 20.3992L33.8803 33.0093L7.7801 44.0994L11.3014 64L49.5119 49.0152L87.6986 64L91.2199 44.0994L65.1435 33.0093L95.4074 20.3992Z"
          fill="black"
        />
      </svg>

      {/* <h3>Loading... </h3> */}
    </div>
  );
};

export function convertTo12HourFormat(time24) {
  // Split the input time string into hours, minutes, and seconds
  const [hours, minutes] = time24.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format and handle midnight (12:00 AM) and noon (12:00 PM)
  const hours12 = hours % 12 || 12;

  // Create the formatted 12-hour time string
  const time12 = `${hours12}:${String(minutes).padStart(2, "0")}${period}`;

  return time12;
}


function App() {
  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const [showInfo, setShowInfo] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  const getbanks = async () => {
    const url = "https://api.marasoftpay.live/getbanks";

    var formdata = new FormData();
    formdata.append("enc_key", "MSFT_Enc_OQIILWSQDWU6KWFRNAOP7B20FL5LP741705184831");

    var config = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => err);
    if (response.status === 'success') {
      dispatch(setBanks(response.data.banks))
      // setBanks(response.data.banks);
    }
    else {
      dispatch(setBanks([]))
    }

  }

  const getHistory = async () => {
    dispatch(setHistoryLoading(true));
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/history`;
      const authToken = localStorage.getItem("vsrz") ? `Token ${JSON.parse(localStorage.getItem("vsrz")).token}` : 'Token ';

      const config = {
        method: "GET",
        headers: {
          reactkey: process.env.REACT_APP_AUTH_KEY,
          "Content-Type": "application/json",
          "Authorization": authToken
        },
      };
      const response = await fetch(url, config)
        .then((data) => data.json())
        .catch((err) => (err));

      dispatch(setHistory(response));
      dispatch(setHistoryLoading(false));
      return response;
    }
    catch (err) {
      // setMessage({ message: 'Failed to Fetch', type: 'error' });
      // console.log(err)
      dispatch(setHistoryLoading(false));
    }
  }

  let authorization;
  if (localStorage.getItem('vsrz')) {
    if (localStorage.getItem('vsrz') === '') {
      authorization = null;
    }
    else {
      authorization = JSON.parse(localStorage.getItem('vsrz')).token
    }
  }

  const getTournamentData = async () => {
    dispatch(setTournamentLoading(true));
    const url = `${process.env.REACT_APP_BASE_URL}/leaderboard`;
    const config = {
      method: "GET",
      headers: {
        reactkey: process.env.REACT_APP_AUTH_KEY,
        Authorization: `Token ${authorization}`,
      }
    };
    const response = await fetch(url, config)
      .then((data) => data.json())
      .catch((err) => (err));

    dispatch(setWeeklyTournament(response));
    dispatch(setTournamentLoading(false));
  }

  useEffect(() => {
    dispatch(getAppData());
    
    if (authenticated) {
      getHistory();
      Ask();
    }
    getTournamentData();
    if (localStorage.getItem("ticket") && localStorage.getItem("games")) {
      let ticket = JSON.parse(localStorage.getItem("ticket"));
      let games = JSON.parse(localStorage.getItem("games"));

      dispatch(setSelectedOptions({ ticket, games }));
    }
  }, [authenticated])

  useEffect(() => {
    getbanks();

    if (localStorage.getItem('tour')) {
      dispatch(setCurrentTour(localStorage.getItem('tour')))
    }

    if (!localStorage.getItem('infoCount')) {
      localStorage.setItem('infoCount', 1);
      setShowInfo(true);
    } else {
      if (parseInt(localStorage.getItem('infoCount')) < 5) {
        let count = parseInt(localStorage.getItem('infoCount')) + 1;
        localStorage.setItem('infoCount', count);
        setShowInfo(true);
      }
    }

    if(!localStorage.getItem('tutorial')){
      localStorage.setItem('tutorial', 1)
      setShowTutorial(true);
    }else{
      if(parseInt(localStorage.getItem('tutorial')) < 2){
        let count =  parseInt(localStorage.getItem('tutorial')) + 1;
        localStorage.setItem('tutorial', count);
        setShowTutorial(true);
      }
    }

    const timer = setTimeout(() => {
      setShowInfo(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [])

  return (
    <>
    
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Explore showInfo={showInfo} showTutorial={showTutorial} />}/>
          <Route path="games" element={<Games />} />
          <Route path="history" element={<History />} />
          <Route path="profile" element={<Profile />} />
          <Route path="select/regions" element={<Regions />} />
          <Route path="select/competitions/:countryId" element={<Competitions />} />
          <Route path="select/fixtures/:competitionId" element={<Fixtures />} />
          <Route path="fixture/:fixtureId" element={<FixtureDetails />} />
          <Route path="login" element={<Login />} />
          <Route path="signup/:ref?" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/find-account" element={<FindAccount />} />
          <Route path="reset-password/:token" element={<ChangePassword />} />
          <Route path="join/:id" element={<Join />} />
          <Route path="create-league" element={<CreateLeague />} />
          <Route path='ticket/:id' element={<TicketPage />} />
          <Route path='game/:id' element={<Game />} />
          <Route path='join-league/:pair_code' element={<JoinLeague />} />
          <Route path='league/:pair_code' element={<InLeague />} />
          <Route path='leaderboards' element={<Tournaments />} />
          <Route path='pool/:name' element={<CupPage />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
      <Toaster toastOptions={{
        error: {
          duration: 4000,
          style: {
            background: 'red',
            color: 'white',
            fontSize: '10px'
          }
        },
        inform: {
          duration: 10000,
          style: {
            background: 'orange',
            color: 'white',
            fontSize: '10px'
          }
        }
      }} />

    </>
  );
}

export default App;

import TutorialComponent from "./TutorialComponent";

export default [
    {
      target: '[data-tut="new-game-step-1"]',
      content: <TutorialComponent image={null} text={<p>
        Play against one opponent. Get the highest score to win.
        
      </p>} />,
      disableBeacon: true,
      // hideFooter: true,
      // offset:0,
    },
    {
      target: '[data-tut="new-game-step-2"]',
      content: <TutorialComponent image={null} text={<p>A multiplayer game where the player with the highest score wins</p>} />,
      // disableBeacon: true,
      // hideFooter: true,
      // offset:0,
    },
    {
      target: '[data-tut="new-game-step-3"]',
      content: <TutorialComponent image={null} text={<p>
        A multiplayer game where each player can cashout based on their accumulated
        odds
      </p>} />,
      // hideFooter: true,
      // disableBeacon: true,
      // offset:0,
    },
    {
      target: '[data-tut="new-game-step-1"]',
      content: (
        <TutorialComponent
          image={null}
          text={
            <p>
              {" "}
              Let's create your first 1v1 <br />
              <b>Click</b> on the 1v1 tile to create a game
            </p>
          }
        />
      ),
      disableBeacon: true,
      hideFooter: true,
      // offset:0,
    },
  ];
import { useSelector } from "react-redux";
import Layout from "../components/layout";
import { GamesDiv } from "./explore";
import { useEffect } from "react";
import Login from "../components/login";

export default function Games() {
  const games = useSelector((state) => state.general.games?.all);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const cuppools = useSelector((state) => state.general.games?.cuppools);

  useEffect(() => {}, [games]);

  return (
    <>
      {authenticated ? (
        <Layout>
          <div className="games-cont">
            <div className="page-header-text ">
              <h4>
                <b>Games</b>
              </h4>
              <span></span>
            </div>
            <GamesDiv
              games={games ? games : []}
              showPrivate={true}
              cuppools={cuppools}
              
            />
          </div>
        </Layout>
      ) : (
        <Login reload={true} />
      )}
    </>
  );
}

import Layout, { Navbar } from "../components/layout";
import '../index.css';
import '../styles/league.css';
import league_trophy from '../images/league-trophy.webp';
import cup from '../images/cup.png';
import { Picture } from "../components/cards";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPairDetails } from "../redux/slices/ticketSlice";
import def from '../images/default.png'
import { Loading } from "../App";
import Login from "../components/login";
import CountdownTimer from "../components/countdown";
import { Forecast } from '../pages/game';
import { useMediaQuery } from 'react-responsive';
import { Back, } from "./select";
import { Error } from "./profile";
import toast from "react-hot-toast";

export function CreateLeague() {
    const [leagueValues, setLeagueValues] = useState({
        'name': '',
        'stake': '',
        'number_of_participants': '',
        'number_of_games': ''
    })
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();


    function changeValues(e, type) {
        type === 'games' && (
            setLeagueValues({
                ...leagueValues,
                number_of_games: e.target.value
            })
        )
        type === 'participants' && (
            setLeagueValues({
                ...leagueValues,
                number_of_participants: e.target.value
            })
        )
    }

    useEffect(() => {
        if ((leagueValues.name !== '') && (leagueValues.stake !== '') && (leagueValues.stake >= 500) && (leagueValues.number_of_participants >= 3) && (leagueValues.number_of_games !== 0) && (leagueValues.number_of_games !== '')) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [leagueValues])

    const createLeague = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/create_league`;
        setLoading(true);
        const payload = {
            name: leagueValues.name,
            amount: leagueValues.stake,
            number_of_games: leagueValues.number_of_games,
            number_of_participants: leagueValues.number_of_participants,
        };

        const config = {
            method: "POST",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
                Authorization: `Token ${JSON.parse(localStorage.getItem("vsrz")).token
                    }`,
            },
            body: JSON.stringify(payload),
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);
        if (response.success) {
            dispatch(
                setPairDetails({
                    id: response.league_id,
                    stake: leagueValues.stake,
                    number_of_games: leagueValues.number_of_games,
                    pairType: "league",
                })
            );
            window.location.href = `/games`;
        }
        else {
            setError(response.message);
            // navigate('/games');
            // setMessage({ message: response.message, type: 'error' });
        }
        setLoading(false);

    };

    return (
        <Layout>
            <div className="between-row" style={{padding:'1rem'}}>
                <h4>
                    <b>Create League</b>
                </h4>
                <span>

                </span>
            </div>
            {error && !loading && (
                <Error error={error} setError={setError} />
            )}
            <div className="my-3 justify-center" style={{padding:'1rem'}}>
                <div className="col-12 col-md-8 justify-center flex-column">
                    <div className="p-4 create-league-box w-50 rounded-3 between-col text-white league-boxes">
                        <div className="between-col">
                            <Picture image={league_trophy} size='20px' />

                            <div className="small">Enter League Name</div>
                        </div>
                        <Textfield type='text' val='Enter League Name' leagueValues={leagueValues} setLeagueValues={setLeagueValues} name={leagueValues.name} />
                    </div>
                    <div className="my-3 text-center w-50 league-boxes">
                        <div className="small">Entry Amount</div>
                        <Textfield type='number' color='black' val='500' leagueValues={leagueValues} setLeagueValues={setLeagueValues} stake={leagueValues.stake} />
                        <div className="my-3">
                            <div className="text-start mb-3">
                                <label className="small">Number of games</label>
                                <input type="number" className="w-100 rounded p-2" style={{ border: '1px solid lightgrey' }} placeholder="Minimum 1" value={leagueValues.number_of_games} onChange={(e) => changeValues(e, 'games')} />
                            </div>
                            <div className="text-start mb-3">
                                <label className="small">Number of participants</label>
                                <input type="number" className="w-100 rounded p-2" style={{ border: '1px solid lightgrey' }} placeholder="Minimum 3" value={leagueValues.number_of_participants} onChange={(e) => changeValues(e, 'participants')} />
                            </div>
                        </div>
                    </div>
                    <button className="btn orange w-25 button" disabled={disabled || loading} onClick={createLeague}>
                        {loading ? (
                            <div className="spinner-grow" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : 'Continue'}
                    </button>
                </div>
            </div>
        </Layout>
    )
}

function Textfield(props) {
    const { type, color = 'white', val, setLeagueValues, name, leagueValues, stake } = props;
    const changeValues = (e) => {
        type === 'number' && (
            setLeagueValues({
                ...leagueValues,
                stake: e.target.value,
            })
        )
        type === 'text' && (
            setLeagueValues({
                ...leagueValues,
                name: e.target.value,
            })
        )
    }

    return (
        <div className="mt-3 border-bottom rounded text-white">
            <input type={type} className="text-center fs-3 w-100 fcs" style={{ border: "none", background: 'transparent', color: color }} placeholder={val} value={type === 'text' ? name : stake} onChange={changeValues} />
        </div>
    )
}

export function JoinLeague() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pair_code } = useParams();
    const authenticated = useSelector(state => state.auth.authenticated);
    const [loading, setLoading] = useState(false);
    const [leagueData, setLeagueData] = useState(null);


    function join() {
        dispatch(
            setPairDetails({
                id: leagueData.league_details.id,
                stake: leagueData.league_details.entry_amount,
                number_of_games: leagueData.league_details.number_of_games,
                pairType: "league",
                points: leagueData.league_details.points,
                entry_points: leagueData.league_details.versuz_points,
                max_date: leagueData.league_details.max_date
            })
        );
        navigate('/select/regions');
    }

    const getLeagueData = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/league/${pair_code}`;

        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
                Authorization: `Token ${authenticated &&
                    JSON.parse(localStorage.getItem("vsrz")).token
                    }`,
            },
        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);


        if (response.success) {
            setLeagueData(response);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (leagueData) {
            leagueData.present && navigate(`/league/${leagueData.league_details.pair_code}`)
        }
    }, [leagueData])

    useEffect(() => {
        getLeagueData();
    }, []);

    return (
        <Layout>
            <div className="between-row">
                <h4>
                    <b>Join League</b>
                </h4>
                <span>

                </span>
            </div>
            {(loading || !leagueData) ? <Loading /> : (
                <div className="my-3 justify-center">
                    <div className="col-10 justify-center flex-column">
                        <div className="p-2 create-league-box w-50 rounded-3 between-col text-white">
                            <div className="between-row col-12">
                                <Picture image={league_trophy} size='20px' />

                                {/* <div className="small text-center">
                                    <div>Participants</div>
                                    <div className="medium">
                                        {leagueData.league_details.participants - leagueData.league_details.slots_left}/{leagueData.league_details.participants}
                                    </div>
                                </div> */}
                                <div className="between-col">
                                    <div className="seven">
                                        ₦{leagueData.league_details.potential_win}
                                    </div>
                                    <div className="smaller">
                                        *Cash Prize
                                    </div>
                                </div>
                            </div>
                            <h4 className="my-3">
                                <b>{leagueData?.league_details.name}</b>
                            </h4>
                            <div className="text-center">
                                <div className="small">Entry Amount</div>
                                <div><b>
                                    {leagueData.league_details.points ? (
                                        <>
                                            <svg width="23" height="23" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                                <path d="M0.00939941 8.85865V25.4124L12.9147 33.6892L25.82 25.4124V8.85865L12.9147 0.581787L0.00939941 8.85865Z" fill="#ECB20C" />
                                                <path d="M8.22915 14.2778L12.9147 4.1516V0.581787L0.00939941 8.85865L8.22915 14.2778Z" fill="url(#paint0_linear_2712_29133)" />
                                                <path d="M19.0201 20.5166L12.9148 30.1192V33.6891L25.8201 25.4122L23.0369 23.6272L19.0201 20.5166Z" fill="url(#paint1_linear_2712_29133)" />
                                                <path d="M12.6915 25.8801L2.79241 23.6274L0.00939941 25.4124L12.9147 33.6893V30.1195L12.6915 25.8801Z" fill="url(#paint2_linear_2712_29133)" />
                                                <path d="M13.1353 8.54164L23.0369 10.6436L25.8201 8.85865L12.9148 0.581787V4.1516L13.1353 8.54164Z" fill="url(#paint3_linear_2712_29133)" />
                                                <path d="M8.67527 20.0789L3.21023 10.9351L0.00939941 8.85864V25.4124L2.79241 23.6274L8.67527 20.0789Z" fill="url(#paint4_linear_2712_29133)" />
                                                <path d="M17.1539 20.0789L22.619 10.9351L25.82 8.85864V25.4124L23.0368 23.6274L17.1539 20.0789Z" fill="url(#paint5_linear_2712_29133)" />
                                                <g filter="url(#filter0_i_2712_29133)">
                                                    <path d="M2.7926 10.6437V23.6274L12.9149 30.1194L23.037 23.6274V10.6437L12.9149 4.15161L2.7926 10.6437Z" fill="url(#paint6_diamond_2712_29133)" />
                                                </g>
                                                <path d="M5.57699 10.6885L5.82116 9.71166C5.85245 9.58635 5.91734 9.4718 6.00871 9.38042C6.10009 9.28904 6.21464 9.22415 6.33995 9.19286L7.31679 8.9487C7.34311 8.94208 7.34311 8.90466 7.31679 8.89821L6.33995 8.65404C6.21464 8.62275 6.10009 8.55786 6.00871 8.46649C5.91734 8.37511 5.85245 8.26056 5.82116 8.13525L5.57699 7.15841C5.57037 7.13209 5.53296 7.13209 5.5265 7.15841L5.28234 8.13525C5.25105 8.26056 5.18616 8.37511 5.09478 8.46649C5.00341 8.55786 4.88885 8.62275 4.76354 8.65404L3.78671 8.89821C3.76039 8.90483 3.76039 8.94224 3.78671 8.9487L4.76354 9.19286C4.88885 9.22415 5.00341 9.28904 5.09478 9.38042C5.18616 9.4718 5.25105 9.58635 5.28234 9.71166L5.5265 10.6885C5.53313 10.7148 5.57054 10.7148 5.57699 10.6885Z" fill="white" />
                                                <path d="M22.9739 21.4029L23.157 20.6702C23.1805 20.5762 23.2292 20.4903 23.2977 20.4217C23.3663 20.3532 23.4522 20.3045 23.5462 20.281L24.2789 20.0978C24.2986 20.0928 24.2986 20.0648 24.2789 20.0599L23.5462 19.8766C23.4522 19.8531 23.3663 19.8044 23.2977 19.7359C23.2292 19.6674 23.1805 19.5815 23.157 19.4874L22.9739 18.7548C22.969 18.7351 22.941 18.7351 22.936 18.7548L22.753 19.4874C22.7294 19.5815 22.6808 19.6674 22.6122 19.7359C22.5437 19.8044 22.4578 19.8531 22.3638 19.8766L21.6311 20.0599C21.6114 20.0648 21.6114 20.0928 21.6311 20.0978L22.3638 20.281C22.4578 20.3045 22.5437 20.3532 22.6122 20.4217C22.6808 20.4903 22.7294 20.5762 22.753 20.6702L22.936 21.4029C22.941 21.4226 22.969 21.4226 22.9739 21.4029Z" fill="white" />
                                                <path d="M22.1269 15.2446L21.1453 16.9684L21.0734 17.1121H17.3385L16.9555 17.8064H20.6664L20.0918 18.788L19.5891 19.6499H15.8781L14.8486 21.4455L13.3642 23.9833H9.74907L9.29418 21.4455L8.98294 19.6499H5.63112L5.48747 18.788L5.31988 17.8064H8.64775L8.52805 17.1121H5.17623L5.15229 16.9684L4.86499 15.2446H8.19287L7.78586 12.8744L7.47462 11.1746H11.6165L11.9038 12.8744L12.3348 15.2446L12.5023 16.2502L12.6699 17.1121L12.7657 17.7106L13.1009 17.1121L13.6037 16.2502L14.1783 15.2446L15.5669 12.8744L16.5245 11.1746H20.7622L19.8045 12.8744L18.4159 15.2446H22.1269Z" fill="black" />
                                                <defs>
                                                    <filter id="filter0_i_2712_29133" x="2.7926" y="4.15161" width="20.3503" height="26.0737" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.105927" dy="0.105927" />
                                                        <feGaussianBlur stdDeviation="0.222446" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.529412 0 0 0 0 0.713726 0 0 0 0 0.0196078 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2712_29133" />
                                                    </filter>
                                                    <linearGradient id="paint0_linear_2712_29133" x1="6.5028" y1="4.77067" x2="8.42669" y2="7.14785" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D3FF58" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_2712_29133" x1="18.9703" y1="28.9785" x2="17.5808" y2="26.8448" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D3FF58" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_2712_29133" x1="6.2871" y1="29.7353" x2="8.60171" y2="27.2939" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_2712_29133" x1="19.4922" y1="4.59325" x2="17.1771" y2="7.06458" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#86B504" />
                                                    </linearGradient>
                                                    <linearGradient id="paint4_linear_2712_29133" x1="-1.14256" y1="17.1328" x2="0.675385" y2="18.9862" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint5_linear_2712_29133" x1="25.82" y1="17.1355" x2="22.6319" y2="17.1355" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#BDFF05" />
                                                        <stop offset="1" stopColor="#86B504" />
                                                    </linearGradient>
                                                    <radialGradient id="paint6_diamond_2712_29133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.9062 16.4708) rotate(-90) scale(13.9956 13.9956)">
                                                        <stop stopColor="#BDFF05" />
                                                        <stop offset="1" stopColor="#D3FF58" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                            {leagueData.league_details.versuz_points}
                                        </>
                                    ) : (
                                        <>₦{leagueData?.league_details.entry_amount}</>
                                    )}
                                </b></div>
                            </div>
                        </div>
                        <div className="my-3 w-50 create-league-box-leaderboard" style={{ height: '40dvh' }}>
                            <LeaderBoard users={leagueData.users} />
                        </div>
                        <button className="btn orange w-25 button" onClick={join}>
                            Continue
                        </button>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export function LeaderBoard(props) {
    const { users, height = '40', main, open, player } = props;
    const [person, setPerson] = useState();
    const [betcodeLoading, setBetcodeLoading] = useState(false);
    const [forecasts, setForecasts] = useState([]);
    const [fixture, setFixture] = useState();

    const handleClick = (fixture) => {
        setFixture(fixture);
    }

    function getUserSelections(user) {
        setPerson(user.username)
        const getUserForecasts = async (code_id) => {
            setBetcodeLoading(true);
            const url = `${process.env.REACT_APP_BASE_URL}/new_betcode/${code_id}`;
            const config = {
                method: "GET",
                headers: {
                    reactkey: process.env.REACT_APP_AUTH_KEY,
                    // "Content-Type": "application/json",
                    Authorization: `Token ${JSON.parse(localStorage.getItem("vsrz")).token}`,
                },
            };
            const response = await fetch(url, config)
                .then((data) => data.json())
                .catch((err) => { });

            if (response.success) {
                setForecasts(response.data.fixtures);
            } else {
                // setMessage({ message: response.message, type: 'error' });
            }
            setBetcodeLoading(false);
        }
        if (player !== user.user.username) {
            setPerson(user.user.username)
            getUserForecasts(user.id);
        }
    }

    useEffect(() => {

    }, [forecasts])

    useEffect(() => {

    }, [users, main])


    return (
        <>
            {(users?.length > 0 || main?.length > 0) && (
                <div className="sans" style={{ height: `${height}dvh`, overflowY: 'auto' }}>
                    <div className="modal fade" id="opponentModal" tabIndex="-1" aria-labelledby="opponentModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title medium" id="opponentModalLabel">{person}'s games</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-center">
                                    {betcodeLoading ? (
                                        <div className="spinner-grow" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) :
                                        <div className="between-row flex-wrap">
                                            {forecasts.map((forecast, index) => {
                                                return <Forecast key={index} forecast={forecast} size='col-4' />;
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table align-middle">
                        <thead className="">
                            <tr className="medium">
                                <th>*</th>
                                <th>User</th>
                                {main && (
                                    <>
                                        <th>Played</th>
                                        <th>Won</th>
                                        <th>Odds Won</th>
                                        <th>
                                            <svg width="23" height="23" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                                <path d="M0.00939941 8.85865V25.4124L12.9147 33.6892L25.82 25.4124V8.85865L12.9147 0.581787L0.00939941 8.85865Z" fill="#ECB20C" />
                                                <path d="M8.22915 14.2778L12.9147 4.1516V0.581787L0.00939941 8.85865L8.22915 14.2778Z" fill="url(#paint0_linear_2712_29133)" />
                                                <path d="M19.0201 20.5166L12.9148 30.1192V33.6891L25.8201 25.4122L23.0369 23.6272L19.0201 20.5166Z" fill="url(#paint1_linear_2712_29133)" />
                                                <path d="M12.6915 25.8801L2.79241 23.6274L0.00939941 25.4124L12.9147 33.6893V30.1195L12.6915 25.8801Z" fill="url(#paint2_linear_2712_29133)" />
                                                <path d="M13.1353 8.54164L23.0369 10.6436L25.8201 8.85865L12.9148 0.581787V4.1516L13.1353 8.54164Z" fill="url(#paint3_linear_2712_29133)" />
                                                <path d="M8.67527 20.0789L3.21023 10.9351L0.00939941 8.85864V25.4124L2.79241 23.6274L8.67527 20.0789Z" fill="url(#paint4_linear_2712_29133)" />
                                                <path d="M17.1539 20.0789L22.619 10.9351L25.82 8.85864V25.4124L23.0368 23.6274L17.1539 20.0789Z" fill="url(#paint5_linear_2712_29133)" />
                                                <g filter="url(#filter0_i_2712_29133)">
                                                    <path d="M2.7926 10.6437V23.6274L12.9149 30.1194L23.037 23.6274V10.6437L12.9149 4.15161L2.7926 10.6437Z" fill="url(#paint6_diamond_2712_29133)" />
                                                </g>
                                                <path d="M5.57699 10.6885L5.82116 9.71166C5.85245 9.58635 5.91734 9.4718 6.00871 9.38042C6.10009 9.28904 6.21464 9.22415 6.33995 9.19286L7.31679 8.9487C7.34311 8.94208 7.34311 8.90466 7.31679 8.89821L6.33995 8.65404C6.21464 8.62275 6.10009 8.55786 6.00871 8.46649C5.91734 8.37511 5.85245 8.26056 5.82116 8.13525L5.57699 7.15841C5.57037 7.13209 5.53296 7.13209 5.5265 7.15841L5.28234 8.13525C5.25105 8.26056 5.18616 8.37511 5.09478 8.46649C5.00341 8.55786 4.88885 8.62275 4.76354 8.65404L3.78671 8.89821C3.76039 8.90483 3.76039 8.94224 3.78671 8.9487L4.76354 9.19286C4.88885 9.22415 5.00341 9.28904 5.09478 9.38042C5.18616 9.4718 5.25105 9.58635 5.28234 9.71166L5.5265 10.6885C5.53313 10.7148 5.57054 10.7148 5.57699 10.6885Z" fill="white" />
                                                <path d="M22.9739 21.4029L23.157 20.6702C23.1805 20.5762 23.2292 20.4903 23.2977 20.4217C23.3663 20.3532 23.4522 20.3045 23.5462 20.281L24.2789 20.0978C24.2986 20.0928 24.2986 20.0648 24.2789 20.0599L23.5462 19.8766C23.4522 19.8531 23.3663 19.8044 23.2977 19.7359C23.2292 19.6674 23.1805 19.5815 23.157 19.4874L22.9739 18.7548C22.969 18.7351 22.941 18.7351 22.936 18.7548L22.753 19.4874C22.7294 19.5815 22.6808 19.6674 22.6122 19.7359C22.5437 19.8044 22.4578 19.8531 22.3638 19.8766L21.6311 20.0599C21.6114 20.0648 21.6114 20.0928 21.6311 20.0978L22.3638 20.281C22.4578 20.3045 22.5437 20.3532 22.6122 20.4217C22.6808 20.4903 22.7294 20.5762 22.753 20.6702L22.936 21.4029C22.941 21.4226 22.969 21.4226 22.9739 21.4029Z" fill="white" />
                                                <path d="M22.1269 15.2446L21.1453 16.9684L21.0734 17.1121H17.3385L16.9555 17.8064H20.6664L20.0918 18.788L19.5891 19.6499H15.8781L14.8486 21.4455L13.3642 23.9833H9.74907L9.29418 21.4455L8.98294 19.6499H5.63112L5.48747 18.788L5.31988 17.8064H8.64775L8.52805 17.1121H5.17623L5.15229 16.9684L4.86499 15.2446H8.19287L7.78586 12.8744L7.47462 11.1746H11.6165L11.9038 12.8744L12.3348 15.2446L12.5023 16.2502L12.6699 17.1121L12.7657 17.7106L13.1009 17.1121L13.6037 16.2502L14.1783 15.2446L15.5669 12.8744L16.5245 11.1746H20.7622L19.8045 12.8744L18.4159 15.2446H22.1269Z" fill="black" />
                                                <defs>
                                                    <filter id="filter0_i_2712_29133" x="2.7926" y="4.15161" width="20.3503" height="26.0737" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.105927" dy="0.105927" />
                                                        <feGaussianBlur stdDeviation="0.222446" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.529412 0 0 0 0 0.713726 0 0 0 0 0.0196078 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2712_29133" />
                                                    </filter>
                                                    <linearGradient id="paint0_linear_2712_29133" x1="6.5028" y1="4.77067" x2="8.42669" y2="7.14785" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D3FF58" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_2712_29133" x1="18.9703" y1="28.9785" x2="17.5808" y2="26.8448" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#D3FF58" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_2712_29133" x1="6.2871" y1="29.7353" x2="8.60171" y2="27.2939" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_2712_29133" x1="19.4922" y1="4.59325" x2="17.1771" y2="7.06458" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#86B504" />
                                                    </linearGradient>
                                                    <linearGradient id="paint4_linear_2712_29133" x1="-1.14256" y1="17.1328" x2="0.675385" y2="18.9862" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#CAFF37" />
                                                        <stop offset="1" stopColor="#ACE805" />
                                                    </linearGradient>
                                                    <linearGradient id="paint5_linear_2712_29133" x1="25.82" y1="17.1355" x2="22.6319" y2="17.1355" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#BDFF05" />
                                                        <stop offset="1" stopColor="#86B504" />
                                                    </linearGradient>
                                                    <radialGradient id="paint6_diamond_2712_29133" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.9062 16.4708) rotate(-90) scale(13.9956 13.9956)">
                                                        <stop stopColor="#BDFF05" />
                                                        <stop offset="1" stopColor="#D3FF58" />
                                                    </radialGradient>
                                                </defs>
                                            </svg>
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {users?.slice(0, 10).map((user, i) => {
                                return (
                                    <tr key={i} className="small">
                                        <td className="medium">{i + 1}</td>
                                        <td className="align-row-center">
                                            <Picture image={user.profile_pic} size='30' />
                                            <span className="ms-1">{user.username}</span>
                                        </td>
                                    </tr>
                                )
                            })}
                            {main?.map((user, i) => {
                                return (
                                    <tr key={i} className="small cursor" {...(user.user.username !== player && { "data-bs-toggle": "modal", "data-bs-target": "#opponentModal", onClick: () => getUserSelections(user) })}>
                                        <td className="medium">
                                            {!open && (user.outcome === 'WON' || user.outcome === 'DRAW') ? (
                                                <Picture image={cup} size='20px' />
                                            ) : i + 1}
                                        </td>
                                        <td className="align-row-center medium">
                                            <Picture image={user.user.profile_pic} size='30' />
                                            <span className="ms-1">{user.user.username}</span>
                                        </td>
                                        <td>{user.played}</td>
                                        <td>{user.number_of_wins}</td>
                                        <td>{user.odds_won}</td>
                                        <td>{user.vzps}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
}

export function getOrdinalSuffix(number) {
    const lastDigit = number % 10;
    const suffixes = ["th", "st", "nd", "rd"];

    // Handle special cases (11th, 12th, 13th)
    const specialCase = number % 100 >= 11 && number % 100 <= 13;

    return specialCase ? `th` : suffixes[lastDigit] || `th`;
}

export function InLeague() {
    const { pair_code } = useParams();
    const [loading, setLoading] = useState(false);
    const [leagueData, setLeagueData] = useState(null);
    const authenticated = useSelector(state => state.auth.authenticated);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [fixture, setFixture] = useState();
    const navigate = useNavigate();

    const handleClick = (fixture) => {
        setFixture(fixture);
    }

    const getLeagueData = async () => {
        setLoading(true)
        const url = `${process.env.REACT_APP_BASE_URL}/match/${pair_code}`;

        const config = {
            method: "GET",
            headers: {
                reactkey: process.env.REACT_APP_AUTH_KEY,
                "Content-Type": "application/json",
                Authorization: `Token ${JSON.parse(localStorage.getItem("vsrz")).token
                    }`,
            },

        };

        const response = await fetch(url, config)
            .then((data) => data.json())
            .catch((err) => err);

        if (response.success) {
            setLeagueData(response);
        } else {
            if (!response.present) {
                navigate(`/join-league/${pair_code}`)
            } else {
                navigate('/')
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (authenticated) {
            getLeagueData()
        }
    }, [])

    let status;
    let status_style;
    if (leagueData) {
        if (leagueData.league_data.open) {
            status = 'On-going';
            status_style = {
                backgroundColor: '#8C8F9254',
                color: '#FFFFFF'
            }
        }
    }

    function invite() {
        let link = `https://app.versuz.co/join-league/${pair_code}`;
        if (navigator.share) {
            navigator.share({
                url: `${link}`,
                // text: `Join me in a thrilling 1v1 showdown on Versuz! Select ${games.fixtures.length} predictions and let's battle it out. Entry is ${games.stake === 0 ? 'free' : 'available for a stake of ' + games.stake}`,
            });
        } else {
            navigator.clipboard.writeText(link);
            toast.success('League invite link copied')
        }
    }

    return (
        <>
            {authenticated ? (
                <>
                    {!isMobile ? (
                        <Layout up={0} sides={0} height={100}>
                            {(!leagueData || loading) ? <Loading height='80dvh' /> : (

                                <div className="col-12 between-row" style={{ height: '92dvh' }}>
                                    <div className="col-4 h-100 create-league-box p-2 text-center text-white ">
                                        <div className="between-col" style={{ height: '85%' }}>
                                            <div>
                                                <div>
                                                    <b>{leagueData.league_data.name}</b>
                                                </div>
                                                <div className="my-3">
                                                    <h3 className="m-0"><b>₦{leagueData.league_data.potential_win}</b></h3>
                                                    <div className="small">*Potential Win</div>
                                                </div>
                                            </div>
                                            <div className="between-col">
                                                <Picture image={leagueData.user.profile_pic} size='150' />
                                                <h3 className="mx-0 my-2"><b>{leagueData.user.username}</b></h3>
                                                <div className="small">*{leagueData.league_data.number_of_games - leagueData.league_data.played} games left</div>
                                            </div>
                                            <div>
                                                <h3 className="mx-0 my-2"><b>Position: {leagueData.position}{getOrdinalSuffix(leagueData.position)}</b></h3>
                                                {leagueData.league_data.open && (
                                                    <>
                                                        <div className="justify-center"><CountdownTimer date={leagueData.end_date} /></div>
                                                        <div className="p-2 rounded-pill border my-2" style={status_style}>
                                                            {status}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-4 h-100 p-2">
                                        <div className="between-row">
                                            <b>My games</b>
                                            {leagueData.league_data.open && (
                                                <button className="btn btn-primary" onClick={invite}>Invite</button>
                                            )}
                                        </div>
                                        <div className="my-2 between-row flex-wrap" style={{ maxHeight: '95%', overflow: 'auto' }}>
                                            {leagueData.user_forecasts.map((forecast, i) => {
                                                return <Forecast key={i} forecast={forecast} fixture={fixture} handleClick={() => handleClick(forecast.fixture)} />
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-4 h-100 p-2">
                                        <div><b>Leaderboard</b></div>
                                        <LeaderBoard height='88' main={leagueData.leaderboard} open={leagueData.open} player={leagueData.user.username} />
                                    </div>
                                </div>
                            )}
                        </Layout>
                    ) : (
                        <div>
                            {(!leagueData || loading) ? <Loading height='100dvh' /> : (

                                <div className="col-12" style={{ height: '100dvh' }}>
                                    <div className="col-4 create-league-box p-2 text-center text-white " style={{ maxHeight: '60%' }}>
                                        <div className="w-100 px-4" style={{ position: 'sticky', top: '0', background: '#F6652D' }}>
                                            <Navbar useLogo={true} variant='white' size='50' />
                                        </div>
                                        <div className="between-col" style={{ overflow: 'auto' }}>
                                            <div className="w-100">
                                                <div className=" w-100 text-start between-row">
                                                    <Back />
                                                    <div className="">
                                                        <b>{leagueData.league_data.name}</b>
                                                    </div>
                                                    <div></div>
                                                </div>
                                                <div>
                                                    <div>
                                                        {/* <div>
                                                            <b>{leagueData.league_data.name}</b>
                                                        </div> */}
                                                        <div className="my-3">
                                                            <h1 className="m-0"><b>₦{leagueData.league_data.potential_win}</b></h1>
                                                            <div className="small">*Potential Win</div>
                                                        </div>
                                                    </div>
                                                    <div className="between-col">
                                                        <Picture image={leagueData.user.profile_pic} size='70' />
                                                        <h3 className="mx-0 my-2"><b>{leagueData.user.username}</b></h3>
                                                        <div className="small">*{leagueData.league_data.number_of_games - leagueData.league_data.played} games left</div>
                                                    </div>
                                                    <div>
                                                        <h3 className="mx-0 my-2 medium"><b>Position: {leagueData.position}{getOrdinalSuffix(leagueData.position)}</b></h3>
                                                        {leagueData.league_data.open && (
                                                            <>
                                                                <div className="justify-center small"><CountdownTimer date={leagueData.end_date} /></div>
                                                                <div className="p-2 rounded-pill border my-2 small w-50 mx-auto" style={status_style}>
                                                                    {status}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <div className="col-12 p-2">
                                            <div className="between-row">
                                                <b>My games</b>
                                                {leagueData.league_data.open && (
                                                    <button className="btn btn-primary" onClick={invite}>Invite</button>
                                                )}
                                            </div>
                                            <div className="my-2 between-row flex-nowrap" style={{ maxHeight: '95%', overflow: 'auto' }}>
                                                {leagueData.user_forecasts.map((forecast, i) => {
                                                    return <Forecast key={i} forecast={forecast} size='col-4' fixture={fixture} handleClick={() => handleClick(forecast.fixture)} />
                                                })}
                                            </div>
                                            <div><b>Leaderboard</b></div>
                                            <LeaderBoard height='40' main={leagueData.leaderboard} open={leagueData.open} player={leagueData.user.username} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                </>
            ) : (
                <Login reload={true} />
            )}

        </>
    )
}
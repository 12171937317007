export default function Svg({ name, state }) {
  if (name == "explore") {
    return (
      <>
        {state == "active" ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 27.3333C11.3629 27.3333 8.78502 26.5513 6.59236 25.0862C4.39971 23.6211 2.69074 21.5388 1.68157 19.1024C0.672403 16.6661 0.408359 13.9852 0.922828 11.3988C1.4373 8.81235 2.70717 6.43657 4.57187 4.57187C6.43657 2.70717 8.81235 1.4373 11.3988 0.922828C13.9852 0.408359 16.6661 0.672403 19.1024 1.68157C21.5388 2.69074 23.6211 4.39971 25.0862 6.59236C26.5513 8.78502 27.3333 11.3629 27.3333 14C27.3293 17.5349 25.9232 20.924 23.4236 23.4236C20.924 25.9232 17.5349 27.3293 14 27.3333ZM17.2381 12.6666L21.619 14L17.2381 15.3333L19.4285 19.4285L15.3333 17.2381L14 21.619L12.6666 17.2381L8.57139 19.4285L10.7619 15.3333L6.38091 14L10.7619 12.6666L8.57139 8.57139L12.6666 10.7619L14 6.38091L15.3333 10.7619L19.4285 8.57139L17.2381 12.6666Z"
              fill="#FE4E40"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.59248 25.0863C8.78514 26.5514 11.363 27.3334 14.0001 27.3334C17.5351 27.3293 20.9241 25.9233 23.4237 23.4237C25.9233 20.9241 27.3294 17.535 27.3334 14C27.3334 11.3629 26.5514 8.78508 25.0864 6.59242C23.6213 4.39977 21.5389 2.6908 19.1025 1.68163C16.6662 0.672464 13.9853 0.40842 11.3989 0.922889C8.81247 1.43736 6.4367 2.70724 4.572 4.57194C2.7073 6.43664 1.43742 8.81241 0.92295 11.3988C0.408481 13.9852 0.672525 16.6661 1.68169 19.1025C2.69086 21.5388 4.39983 23.6212 6.59248 25.0863ZM7.65071 4.49752C9.53013 3.24173 11.7397 2.57146 14.0001 2.57146C17.0301 2.57498 19.9349 3.7802 22.0774 5.9227C24.2199 8.06521 25.4251 10.9701 25.4287 14C25.4287 16.2604 24.7584 18.47 23.5026 20.3494C22.2468 22.2288 20.4619 23.6936 18.3736 24.5587C16.2853 25.4237 13.9874 25.65 11.7705 25.209C9.55356 24.768 7.51718 23.6796 5.91887 22.0812C4.32055 20.4829 3.23209 18.4466 2.79111 16.2296C2.35014 14.0127 2.57646 11.7148 3.44146 9.6265C4.30647 7.5382 5.77129 5.7533 7.65071 4.49752ZM17.2382 12.6667L21.6191 14L17.2382 15.3334L19.4287 19.4286L15.3334 17.2381L14.0001 21.6191L12.6668 17.2381L8.57151 19.4286L10.762 15.3334L6.38104 14L10.762 12.6667L8.57151 8.57145L12.6668 10.7619L14.0001 6.38098L15.3334 10.7619L19.4287 8.57145L17.2382 12.6667Z"
              fill="#8C8F92"
            />
          </svg>
        )}
      </>
    );
  } else if (name == "games") {
    return (
      <>
        {state == "active" ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 30 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3333 7.66667V5M12.3333 21V18.3333M12.3333 14.3333V11.6667M6.99996 25C4.05444 25 1.66663 22.6122 1.66663 19.6667V18.3333C1.66663 17.597 2.27996 17.0218 2.97582 16.7808C4.54201 16.2386 5.66663 14.7506 5.66663 13C5.66663 11.2494 4.54201 9.7614 2.97582 9.21916C2.27996 8.97825 1.66663 8.40305 1.66663 7.66667V6.33333C1.66663 3.38781 4.05444 1 6.99996 1H23C25.9455 1 28.3333 3.38781 28.3333 6.33333V7.66667C28.3333 8.40305 27.72 8.97825 27.0241 9.21916C25.4579 9.7614 24.3333 11.2494 24.3333 13C24.3333 14.7506 25.4579 16.2386 27.0241 16.7808C27.72 17.0218 28.3333 17.597 28.3333 18.3333V19.6667C28.3333 22.6122 25.9455 25 23 25H6.99996Z"
              stroke="#8C8F92"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.99996 24C3.05444 24 0.666626 21.6122 0.666626 18.6667V17.3333C0.666626 16.597 1.27996 16.0218 1.97582 15.7808C3.54201 15.2386 4.66663 13.7506 4.66663 12C4.66663 10.2494 3.54201 8.7614 1.97582 8.21916C1.27996 7.97825 0.666626 7.40305 0.666626 6.66667V5.33333C0.666626 2.38781 3.05444 0 5.99996 0H11.3333H22C24.9455 0 27.3333 2.38781 27.3333 5.33333V6.66667C27.3333 7.40305 26.72 7.97825 26.0241 8.21916C24.4579 8.7614 23.3333 10.2494 23.3333 12C23.3333 13.7506 24.4579 15.2386 26.0241 15.7808C26.72 16.0218 27.3333 16.597 27.3333 17.3333V18.6667C27.3333 21.6122 24.9455 24 22 24H11.3333H5.99996ZM12.3333 4C12.3333 3.44772 11.8856 3 11.3333 3C10.781 3 10.3333 3.44772 10.3333 4V6.66667C10.3333 7.21895 10.781 7.66667 11.3333 7.66667C11.8856 7.66667 12.3333 7.21895 12.3333 6.66667V4ZM11.3333 16.3333C11.8856 16.3333 12.3333 16.781 12.3333 17.3333V20C12.3333 20.5523 11.8856 21 11.3333 21C10.781 21 10.3333 20.5523 10.3333 20V17.3333C10.3333 16.781 10.781 16.3333 11.3333 16.3333ZM12.3333 10.6667C12.3333 10.1144 11.8856 9.66667 11.3333 9.66667C10.781 9.66667 10.3333 10.1144 10.3333 10.6667V13.3333C10.3333 13.8856 10.781 14.3333 11.3333 14.3333C11.8856 14.3333 12.3333 13.8856 12.3333 13.3333V10.6667Z"
              fill="#FE4E40"
            />
          </svg>
        )}
      </>
    );
  } else if (name == "newGame") {
    return (
      <svg
        
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor new_game_btn"
      >
        <rect
          x="2.5625"
          y="2.5625"
          width="56.875"
          height="56.875"
          rx="13.8125"
          fill="#FE4E40"
        />
        <rect
          x="2.5625"
          y="2.5625"
          width="56.875"
          height="56.875"
          rx="13.8125"
          stroke="#FFEDEC"
          strokeWidth="4.375"
        />
        <path
          opacity="0.4"
          d="M40.7223 31C40.7223 36.3694 36.3695 40.7222 31.0001 40.7222C25.6306 40.7222 21.2778 36.3694 21.2778 31C21.2778 25.6306 25.6306 21.2778 31.0001 21.2778C36.3695 21.2778 40.7223 25.6306 40.7223 31Z"
          fill="#FCFDFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.7292 27.1111C31.7292 26.7084 31.4027 26.3819 31 26.3819C30.5973 26.3819 30.2708 26.7084 30.2708 27.1111V30.2708H27.1111C26.7084 30.2708 26.382 30.5972 26.382 31C26.382 31.4027 26.7084 31.7291 27.1111 31.7291H30.2708V34.8888C30.2708 35.2915 30.5973 35.618 31 35.618C31.4027 35.618 31.7292 35.2915 31.7292 34.8888V31.7291H34.8889C35.2916 31.7291 35.6181 31.4027 35.6181 31C35.6181 30.5972 35.2916 30.2708 34.8889 30.2708H31.7292V27.1111Z"
          fill="#FCFDFD"
        />
      </svg>
    );
  } else if (name == "history") {
    return (
      <>
        {state == "active" ? (
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.75 20C16.2728 20 20.75 15.5228 20.75 10C20.75 4.47715 16.2728 0 10.75 0C5.22715 0 0.75 4.47715 0.75 10C0.75 15.5228 5.22715 20 10.75 20ZM11.5 5C11.5 4.58579 11.1642 4.25 10.75 4.25C10.3358 4.25 10 4.58579 10 5V10C10 10.3228 10.2066 10.6094 10.5128 10.7115L13.5128 11.7115C13.9058 11.8425 14.3305 11.6301 14.4615 11.2372C14.5925 10.8442 14.3801 10.4195 13.9872 10.2885L11.5 9.45943V5Z"
              fill="#FE4E40"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 15H14.25C14.25 15.3228 14.4565 15.6094 14.7628 15.7115L15 15ZM15.75 8.33329C15.75 7.91908 15.4142 7.58329 15 7.58329C14.5857 7.58329 14.25 7.91908 14.25 8.33329H15.75ZM18.7628 17.0448C19.1557 17.1758 19.5805 16.9634 19.7115 16.5705C19.8425 16.1775 19.6301 15.7528 19.2371 15.6218L18.7628 17.0448ZM15.75 15V8.33329H14.25V15H15.75ZM14.7628 15.7115L18.7628 17.0448L19.2371 15.6218L15.2371 14.2884L14.7628 15.7115ZM27.5833 15C27.5833 21.9495 21.9495 27.5833 15 27.5833V29.0833C22.778 29.0833 29.0833 22.778 29.0833 15H27.5833ZM15 27.5833C8.05038 27.5833 2.41663 21.9495 2.41663 15H0.916626C0.916626 22.778 7.22195 29.0833 15 29.0833V27.5833ZM2.41663 15C2.41663 8.05038 8.05038 2.41663 15 2.41663V0.916626C7.22195 0.916626 0.916626 7.22195 0.916626 15H2.41663ZM15 2.41663C21.9495 2.41663 27.5833 8.05038 27.5833 15H29.0833C29.0833 7.22195 22.778 0.916626 15 0.916626V2.41663Z"
              fill="#8C8F92"
            />
          </svg>
        )}
      </>
    );
  } else if (name == "profile") {
    return <>
    <svg
                width="20"
                height="20"
                viewBox="0 0 22 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5833 6.33333C15.5833 8.86464 13.5313 10.9167 11 10.9167V12.4167C14.3597 12.4167 17.0833 9.69307 17.0833 6.33333H15.5833ZM11 10.9167C8.46865 10.9167 6.41663 8.86464 6.41663 6.33333H4.91663C4.91663 9.69307 7.64023 12.4167 11 12.4167V10.9167ZM6.41663 6.33333C6.41663 3.80203 8.46865 1.75 11 1.75V0.25C7.64023 0.25 4.91663 2.9736 4.91663 6.33333H6.41663ZM11 1.75C13.5313 1.75 15.5833 3.80203 15.5833 6.33333H17.0833C17.0833 2.9736 14.3597 0.25 11 0.25V1.75ZM19.5833 20.3333C19.5833 21.1849 18.8735 22.1577 17.2642 22.9623C15.6998 23.7446 13.4861 24.25 11 24.25V25.75C13.6685 25.75 16.1214 25.2108 17.935 24.304C19.7038 23.4196 21.0833 22.0591 21.0833 20.3333H19.5833ZM11 24.25C8.51382 24.25 6.30012 23.7446 4.73571 22.9623C3.12643 22.1577 2.41663 21.1849 2.41663 20.3333H0.916626C0.916626 22.0591 2.29616 23.4196 4.06489 24.304C5.87848 25.2108 8.33144 25.75 11 25.75V24.25ZM2.41663 20.3333C2.41663 19.4818 3.12643 18.509 4.73571 17.7043C6.30012 16.9221 8.51382 16.4167 11 16.4167V14.9167C8.33144 14.9167 5.87848 15.4559 4.06489 16.3627C2.29616 17.247 0.916626 18.6075 0.916626 20.3333H2.41663ZM11 16.4167C13.4861 16.4167 15.6998 16.9221 17.2642 17.7043C18.8735 18.509 19.5833 19.4818 19.5833 20.3333H21.0833C21.0833 18.6075 19.7038 17.247 17.935 16.3627C16.1214 15.4559 13.6685 14.9167 11 14.9167V16.4167Z"
                //   fill={`${activePage === "Profile" ? "" : ""}`}
                  fill={`${state === 'active' ? '#FE4E40' : '#8C8F92'}`}
                />
              </svg>
    
    </>;
  }else if(name == "explore-lg"){
    return(
      <svg
                width="62"
                height="62"
                viewBox="0 0 62 62"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor new_game_btn"
              >
                <rect
                  x="2.5625"
                  y="2.5625"
                  width="56.875"
                  height="56.875"
                  rx="13.8125"
                  fill="#FE4E40"
                />
                <rect
                  x="2.5625"
                  y="2.5625"
                  width="56.875"
                  height="56.875"
                  rx="13.8125"
                  stroke="#FFEDEC"
                  strokeWidth="4.375"
                />
                <path
                  opacity="0.4"
                  d="M40.7223 31C40.7223 36.3694 36.3695 40.7222 31.0001 40.7222C25.6306 40.7222 21.2778 36.3694 21.2778 31C21.2778 25.6306 25.6306 21.2778 31.0001 21.2778C36.3695 21.2778 40.7223 25.6306 40.7223 31Z"
                  fill="#FCFDFD"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M31.7292 27.1111C31.7292 26.7084 31.4027 26.3819 31 26.3819C30.5973 26.3819 30.2708 26.7084 30.2708 27.1111V30.2708H27.1111C26.7084 30.2708 26.382 30.5972 26.382 31C26.382 31.4027 26.7084 31.7291 27.1111 31.7291H30.2708V34.8888C30.2708 35.2915 30.5973 35.618 31 35.618C31.4027 35.618 31.7292 35.2915 31.7292 34.8888V31.7291H34.8889C35.2916 31.7291 35.6181 31.4027 35.6181 31C35.6181 30.5972 35.2916 30.2708 34.8889 30.2708H31.7292V27.1111Z"
                  fill="#FCFDFD"
                />
              </svg>
    )
  }
}

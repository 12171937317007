import "../index.css";
import "../styles/layout.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ovo from "../images/1v1.webp";
import league from "../images/league.webp";
import pool from "../images/pool.webp";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTour, setAllowButtonClick } from "../redux/slices/tourSlice";
import  newGameSteps  from "../components/tour/newGameTour";
import Joyride, {
  ACTIONS,
  EVENTS,
  ORIGIN,
  STATUS,
  CallBackProps,
} from "react-joyride";
import Svg from "./Svg";

export default function NewGameModal({ handleClick }) {
  const dispatch = useDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const currentTour = useSelector((state) => state.tour.currentTour);
  const [spotlightClicks, setspotlightClicks] = useState(false);

  // const [diableOverlay, setdiableOverlay] = useState(true);
  // const [mounted, setMounted] = useState("false");
  const allowClick = useSelector((state) => state.tour.allowButtonClick);

  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;

    if (action === ACTIONS.CLOSE && origin === ORIGIN.KEYBOARD) {
      // do something
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      //   setRun(false);
    }

    // console.groupCollapsed(type);
    //console.log(data);
    //eslint-disable-line no-console
    // console.groupEnd();
  };

  useEffect(() => {
    if (currentTour == "gameTypeModal") {
      setTimeout(() => {
        if (!run) {
          setRun(true);
        }
        // document.querySelector('.modal-backdrop.show').style.display= "none"
      }, 500);
    } else {
      setRun(false);
    }
    console.log(run);
  }, [currentTour]);

  useEffect(() => {
    console.log(stepIndex);

    if (stepIndex > 0) {
      let mod = document.getElementById("react-joyride-step-0");
      let mod2 = document.getElementById("react-joyride-portal");
      if (mod) {
        mod.style.display = "none";
      }
      if (mod2) {
        mod2.style.display = "none";
      }
    }
    if (stepIndex == 4) {
      setspotlightClicks(true);
      dispatch(setAllowButtonClick("1v1"));
      let mod3 = document.querySelector(".react-joyride__overlay");
      if (mod3) {
        mod3.style.display = "none";
      }
    } else {
      dispatch(setAllowButtonClick("none"));
    }
  }, [stepIndex]);

  const closeTut = (tut) => {
    let tutorial = tut ? tut : "none";
    dispatch(setCurrentTour(tutorial));
  };

  return (
    <>
      <Joyride
        steps={newGameSteps}
        showProgress={true}
        run={run}
        autoStart={false}
        stepIndex={stepIndex}
        continuous={true}
        showSkipButton={false}
        allowClicksThruHole={true}
        disableOverlay={false}
        disableOverlayClose={true}
        spotlightClicks={true}
        callback={handleJoyrideCallback}
        hideCloseButton={true}
      ></Joyride>

      <div
        className="modal fade"
        id="createGameModal"
        tabIndex="-1"
        aria-labelledby="createGameModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content p-2 p-md-4" data-tut="new-game-step-0">
            <div className="modal-header" style={{ border: "none" }}>
              <h1 className="modal-title fs-5" id="createGameModalLabel">
                Create
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              {/* <div> */}

              <div
                className={`border mb-3 between-row p-3 modes cursor ovo main-ovo ${
                  allowClick == "1v1" && "index"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#1v1Modal"
                data-tut="new-game-step-1"
                onClick={() => {
                  closeTut("new1v1");
                }}
                // style={{position:'relative'}}

                // onClick={() => closeTut("new1v1")}
              >
                <div className="col-6">
                  <div className="mode_header">1V1</div>
                  <div className="small">
                    Connect with a friend or find random opponent
                  </div>
                </div>
                <img src={ovo} width="100px" height="100px" />
              </div>

              <div
                className="border mb-3 between-row p-3 modes cursor league main-league"
                data-type="league"
                onClick={handleClick}
                data-bs-dismiss="modal"
                aria-label="Close"
                data-tut="new-game-step-2"
              >
                <div className="col-6">
                  <div className="mode_header">Leagues</div>
                  <div className="small">
                    Play against multiple opponents in a winner takes all!
                  </div>
                </div>
                <img src={league} width="100px" height="100px" />
              </div>

              <div
                className="border mb-3 between-row p-3 modes cursor pool"
                style={{ background: "gray" }}
                data-tut="new-game-step-3"
              >
                <div className="col-6">
                  <div className="mode_header">Pool</div>
                  <div className="small">
                    Cashout based on odds accumulated. Coming soon!
                  </div>
                </div>
                <img src={pool} width="100px" height="100px" />
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
